import React from "react";
import { ResponsiveType } from "../../interface/redux/config";

type Props = {
  responsive: ResponsiveType;
};

const CultureSection5 = ({
  responsive: { PcTablet, Desktop, Tablet, Mobile },
}: Props) => {
  return (
    <div className='mt-[256px] pc:pb-[297px] tablet:pb-[267px] mb:pb-[162px]'>
      <div className='text-white font-bold text-[40px]'>채용 과정 안내</div>
      <PcTablet>
        <div className='text-white text-[16px] leading-[160%] mt-[16px]'>
          <b>+ 입사 지원 </b>: 휴컨의 채용 사이트를 통해 입사 지원이 가능합니다.{" "}
          <br /> <b>+ 서류 검토 </b>: 이력서와 자기소개서, 포트폴리오 등을 통해
          1차 역량을 검토합니다. <br />
          <b> + 실무 면접</b> : 함께 일하게 될 실무진과의 인터뷰를 통해 기술
          역량을 검토합니다. <br /> + 임원 면접 : 최종 마무리 단계로 기술 역량
          외 휴컨의 가치관과 부합한 인재인지 검토합니다.
        </div>
      </PcTablet>
      <Mobile>
        <div className='text-white text-[16px] leading-[160%] mt-[16px]'>
          <b>+ 입사 지원 </b>
          <br /> 휴컨의 채용 사이트를 통해 입사 지원이 가능합니다. <br />
          <b>+ 서류 검토 </b>
          <br /> 이력서와 자기소개서, 포트폴리오 등을 통해 1차 역량을
          검토합니다. <br />
          <b> + 실무 면접</b> <br /> 함께 일하게 될 실무진과의 인터뷰를 통해
          기술 역량을 검토합니다. <br /> + 임원 면접 <br /> 최종 마무리 단계로
          기술 역량 외 휴컨의 가치관과 부합한 인재인지 검토합니다.
        </div>
      </Mobile>
      <div className='text-white text-center nomb:mt-[291px] mb:mt-[228px] flex flex-col items-center'>
        <div className='nomb:text-[24px] mb:text-[18px]'>
          휴컨은 우리의 가치를 이해하고 끊임없이 함께 성장할 구성원을
          기다립니다.
        </div>
        <div className='font-bold nomb:text-[48px] mb:text-[36px] mt-[8px]'>
          휴컨과 함께{" "}
          <Mobile>
            <br />
          </Mobile>{" "}
          해보시겠습니까?
        </div>
        <a className='bg-white text-black flex gap-x-[5px] items-center justify-center h-[44px] w-[184px] text-[16px] font-bold mt-[24px]' href="https://huconn.careers.team/job-descriptions" target="_blank" rel="noreferrer noopener">
          채용중인 공고 보기
          <img src='/assets/culture/arrow.svg' alt='' />
        </a>
        <Desktop>
          <img
            className='mt-[73px]'
            src='/assets/culture/content/culture-section5.png'
            srcSet='/assets/culture/content/culture-section5@2x.png 2x, /assets/culture/content/culture-section5@3x.png 3x'
            alt=''
          />
        </Desktop>
        <Tablet>
          <img
            className='mt-[74px]'
            src='/assets/culture/content/culture-section5-tablet.png'
            srcSet='/assets/culture/content/culture-section5-tablet@2x.png 2x, /assets/culture/content/culture-section5-tablet@3x.png 3x'
            alt=''
          />
        </Tablet>
        <Mobile>
          <img
            className='mt-[48px]'
            src='/assets/culture/content/culture-section5-mb.png'
            srcSet='/assets/culture/content/culture-section5-mb@2x.png 2x, /assets/culture/content/culture-section5-mb@3x.png 3x'
            alt=''
          />
        </Mobile>
      </div>
    </div>
  );
};

export default CultureSection5;
