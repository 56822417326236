import React from "react";
import { Link } from "react-router-dom";

const MainSection1 = () => {
  return (
    <div className="flex items-center flex-col h-[766px] relative pt-[192px] tablet:h-[1034px] ">
      <div className="relative z-10 flex flex-col items-center">
        <div className="text-[32px] text-white font-bold text-center leading-tight mb:text-[24px] font-mont">
          Embracing Humanity <br />
          <span className="bg-main">with Technology</span>
        </div>
        <div className="mt-2 mb-4 text-2xl text-white mb:text-lg">
          기술로 인류를 포용합니다, 휴컨
        </div>
        <Link
          className="w-[189px] h-[42px] bg-gradient text-black font-extrabold text-base transition-all hover:text-white flex items-center justify-center"
          to="/about/intro"
        >
          ABOUT HUCONN
        </Link>
      </div>
      <div className="w-[1440px] absolute bottom-0 mb:w-[768px]">
        <img
          src="/assets/main/section1/main-section1.png"
          srcSet="/assets/main/section1/main-section1@2x.png 2x, /assets/main/section1/main-section1@3x.png 3x"
          alt="back"
        />
      </div>
    </div>
  );
};

export default MainSection1;
