import React from "react";
import { ResponsiveType } from "../../interface/redux/config";

type Props = {
  responsive: ResponsiveType;
};

const CultureSection1 = ({
  responsive: { Mobile, Tablet, Desktop, PcTablet },
}: Props) => {
  return (
    <div className='grid nomb:gap-y-[114px] mb:gap-y-[40px]'>
      <div className='grid gap-y-[16px] pc:pt-[138px] tablet:pt-[133px] mb:pt-[55px]'>
        <div className='text-white nomb:text-[40px] mb:text-[30px] mb:w-[223px] font-bold leading-[140%] ]'>
          휴컨은, <br /> 사람과 미래를 위해 기술을 연구합니다.
        </div>

        <div className='text-white text-[16px] leading-[160%]'>
          지속 성장 가능한 미래를 위해 휴컨 구성원 모두가 함께 하고 있으며,{" "}
          <PcTablet>
            <br />
          </PcTablet>
          우리가 가진 최고의 기술로 더 나은 사회를 만드는데 기여하고자 합니다.
          <br />
          Embracing Humanity with Technology.
        </div>
        <a className='bg-white w-[184px] h-[44px] flex justify-between items-center py-[10px] px-[16px] text-[16px] font-bold' href="https://huconn.careers.team/job-descriptions" target="_blank" rel="noreferrer noopener">
          채용중인 공고 보기
          <img src='/assets/culture/arrow.svg' alt='' />
        </a>
      </div>
      <Desktop>
        <img
          src='/assets/culture/content/culture-section1.png'
          srcSet='/assets/culture/content/culture-section1@2x.png 2x, /assets/culture/content/culture-section1@3x.png 3x'
          alt=''
        />
      </Desktop>
      <Tablet>
        <img
          src='/assets/culture/content/culture-section1-tablet.png'
          srcSet='/assets/culture/content/culture-section1-tablet@2x.png 2x, /assets/culture/content/culture-section1-tablet@3x.png 3x'
          alt=''
        />
      </Tablet>
      <Mobile>
        <img
          src='/assets/culture/content/culture-section1-mb.png'
          srcSet='/assets/culture/content/culture-section1-mb@2x.png 2x, /assets/culture/content/culture-section1-mb@3x.png 3x'
          alt=''
        />
      </Mobile>
    </div>
  );
};

export default CultureSection1;
