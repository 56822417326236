import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { languageType } from "../../../interface/redux/config";

type Props = {
  Open_Nav_layout: {
    title: string;
    type:string;
    list: { title: string; link: string }[];
  }[];
  setIsHover: Dispatch<SetStateAction<boolean>>;
  isHover: boolean;
  language: languageType;
  ChangeLanguage: () => void;
  pathname: string;
  Tablet: ({ children }: { children: JSX.Element }) => JSX.Element | null;
  Mobile: ({ children }: { children: JSX.Element }) => JSX.Element | null;
};
const TabletMobileHeader = ({
  Open_Nav_layout,
  setIsHover,
  isHover,
  language,
  ChangeLanguage,
  pathname,
  Tablet,
  Mobile,
}: Props) => {
  const [OpenIdx, setOpenIdx] = useState<number | undefined>(undefined);
  useEffect(() => {
    Open_Nav_layout.forEach(({type},idx)=>{
      if (pathname.includes(type)) {
        setOpenIdx(idx)
      }
    })
    return () => {
      
    }
  }, [pathname,Open_Nav_layout])
  return (
    <>
      <header className="fixed top-0 left-0 z-50 flex items-center justify-between w-full h-16 px-4 bg-black">
        <Link className="w-28" to={"/"}>
          <img
            src="/assets/header/logo.png"
            srcSet="/assets/header/logo@2x.png 2x, /assets/header/logo@3x.png 3x"
            alt=""
          />
        </Link>
        <div className="flex gap-x-7">
          <button
            className="flex items-center gap-x-2"
            onClick={ChangeLanguage}
          >
            <Tablet>
              <>
                <div className="w-7">
                  <img
                    src={`/assets/header/${
                      language === "kr" ? "korea" : "usa"
                    }.png`}
                    srcSet={`/assets/header/${
                      language === "kr" ? "korea" : "usa"
                    }@2x.png 2x, /assets/header/${
                      language === "kr" ? "korea" : "usa"
                    }@3x.png 3x`}
                    alt=""
                  />
                </div>
                <div className="text-base font-bold text-white">
                  {language === "kr" ? "KOREAN" : "ENGLISH"}
                </div>
              </>
            </Tablet>
            <Mobile>
              <div className="text-base font-bold text-white">
                {language === "kr" ? "KOR" : "ENG"}
              </div>
            </Mobile>
          </button>
          <button
            className="w-[30px]"
            onClick={() => {
              setIsHover(!isHover);
            }}
          >
            <img
              src={`/assets/header/${isHover ? "cancel" : "menu"}.svg`}
              alt="메뉴"
            />
          </button>
        </div>
      </header>
      <div
        className={`fixed left-0 z-30 flex flex-col items-center
         bg-black h-screen w-full transition-all duration-300 ease-in-out ${
           isHover ? "top-16" : "top-[-100vh]"
         }`}
      >
        {Open_Nav_layout.map(({ title, list ,type}, idx) => {
          return type !=="/contact" ?(
            <div
              key={idx}
              className={`flex flex-col items-center w-full overflow-hidden transition-all duration-300 ease-in-out ${
                OpenIdx === idx ? `max-h-[500px]` : "max-h-[69px]"
              }`}
            >
              <div
                className="w-full py-5 text-lg font-bold text-center border-b border-white border-solid cursor-pointer"
                onClick={() => {
                  setOpenIdx(idx);
                }}
              >
                <span className={OpenIdx === idx ? "bg-main" : "text-white"}>
                  {title}
                </span>
              </div>
              {list.map(({ title, link }, idx) => {
                return (
                  <Link
                    key={idx}
                    to={link}
                    className={`font-bold text-sm py-5 bg-gray600 w-full text-center ${
                      link === pathname ? "text-blue" : "text-white"
                    }`}
                  >
                    {title}
                  </Link>
                );
              })}
            </div>
          ) : <div
              key={idx}
              className={`flex flex-col items-center w-full overflow-hidden transition-all duration-300 ease-in-out ${
                OpenIdx === idx ? `max-h-[500px]` : "max-h-[69px]"
              }`}
            >
              <Link
                className="w-full py-5 text-lg font-bold text-center border-b border-white border-solid cursor-pointer"
                to={type}
              >
                <span className={OpenIdx === idx ? "bg-main" : "text-white"}>
                  {title}
                </span>
              </Link>
            </div>;
        })}
      </div>
    </>
  );
};

export default TabletMobileHeader;
