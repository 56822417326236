import React, { useEffect, useRef } from "react";
import { Responsiv } from "../../../../interface/redux/config";

type Props = {
  Desktop: Responsiv;
  Tablet: Responsiv;
  Mobile: Responsiv;
  PcTablet: Responsiv;
  isScroll: boolean;
  data: {
    tag: string;
    title: string;
    sub: string;
    submb: string;
    type: string;
  };
};

const SmartContentBox = ({
  Desktop,
  Tablet,
  Mobile,
  PcTablet,
  data: { tag, title, sub, submb, type },
  isScroll,
}: Props) => {
  const ContentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isScroll && ContentRef.current) {
      console.log(ContentRef.current.scrollHeight);
      ContentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    return () => {};
  }, [isScroll]);

  return (
    <div
      className={`${
        type === "progress"
          ? "pc:mt-[152px] tablet:mt-[272px] pc:mb-[196px] tablet:mb-[343px] mb:my-[174px] mb:bg-subblack mb:pt-[71px] mb:pb-[63px]"
          : ""
      }
      ${type !== "model" ? "tablet:w-[689px] tablet:m-auto" : ""}
      `}
    >
      <div
        className={
          type === "model"
            ? "tablet:w-[689px] nopc:m-auto mb:w-[328px]"
            : " mb:text-center"
        }
      >
        <span className="font-bold nomb:text-lg mb:text-sm bg-main">{tag}</span>
        <div className="text-white font-bold nomb:text-[32px] mb:text-2xl whitespace-pre-line my-[6px]">
          {title}
        </div>
        <div
          className="text-sm text-white whitespace-pre-line laeding-[160%]"
          ref={ContentRef}
        >
          <PcTablet>
            <>{sub}</>
          </PcTablet>
          <Mobile>
            <>{submb}</>
          </Mobile>
        </div>
      </div>
      {type !== "build" ? (
        <div
          className={`flex items-center justify-center bg-subblack mt-[38px] ${
            type === "model"
              ? "pc:h-[429px] tablet:h-[1359px] mb:h-[1234px]"
              : "pc:h-[459px] tablet:h-[423px] mb:h-[732px]"
          } `}
        >
          <Desktop>
            <img
              className="w-fit"
              src={`/assets/business/smart/content/${type}/${type}-pc.png`}
              srcSet={`/assets/business/smart/content/${type}/${type}-pc@2x.png 2x, /assets/business/smart/content/${type}/${type}-pc@3x.png 3x`}
              alt=""
            />
          </Desktop>
          <Tablet>
            <img
              className="w-fit"
              src={`/assets/business/smart/content/${type}/${type}-tablet.png`}
              srcSet={`/assets/business/smart/content/${type}/${type}-tablet@2x.png 2x, /assets/business/smart/content/${type}/${type}-tablet@3x.png 3x`}
              alt=""
            />
          </Tablet>
          <Mobile>
            <img
              className="w-fit"
              src={`/assets/business/smart/content/${type}/${type}-mb.png`}
              srcSet={`/assets/business/smart/content/${type}/${type}-mb@2x.png 2x, /assets/business/smart/content/${type}/${type}-mb@3x.png 3x`}
              alt=""
            />
          </Mobile>
        </div>
      ) : (
        <div className="nomb:mt-[39px] mb:mt-[42px]">
          <PcTablet>
            <div className="flex pc:gap-x-[101px] tablet:gap-x-[59px] mb-[7px]">
              <BuildHead type="before" />
              <BuildHead type="after" />
            </div>
          </PcTablet>
          <div className="grid nomb:gap-y-[15px] mb:gap-y-[1px] mb:bg-gray400">
            {layout.map(({ after, before }, idx) => {
              return (
                <div
                  key={idx}
                  className="flex items-center pc:gap-x-[28px] tablet:gap-x-[14.5px] mb:flex-col mb:gap-y-[18px] bg-black mb:py-[50px]"
                >
                  <BuildContent data={before} type="before" Mobile={Mobile} />
                  <div className="pc:w-[45px] pc:h-[45px] nopc:w-[30px] nopc:h-[30px] mb:rotate-90">
                    <img
                      src="/assets/business/smart/content/build/build-arrow.svg"
                      alt=""
                    />
                  </div>

                  <BuildContent data={after} type="after" Mobile={Mobile} />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default SmartContentBox;

const BuildHead = ({ type }: { type: "before" | "after" }) => {
  return (
    <div
      className={`nomb:text-lg mb:text-base text-white mb:mb-[7px]  pc:w-[523px] tablet:w-[315px] mb:w-[328px] h-[44px] flex items-center justify-center font-bold rounded-t-lg ${
        type === "before" ? "bg-gray400" : "bg-gradient"
      }`}
    >
      {type === "before" ? " 스마트팩토리 구축 전" : "스마트팩토리 구축 후"}
    </div>
  );
};

const BuildContent = ({
  data: { title, content },
  type,
  Mobile,
}: {
  data: {
    title: string;
    content: string[];
  };
  type: "before" | "after";
  Mobile: Responsiv;
}) => {
  return (
    <div>
      <Mobile>
        <BuildHead type={type} />
      </Mobile>
      <div
        className={`bg-gray600 pc:w-[523px] tablet:w-[315px] mb:w-[328px] pc:h-[149px] tablet:h-[250px] pc:pt-[25px] tablet:pt-[41px] pc:pl-[31px]  nopc:flex nopc:flex-col nopc:items-center rounded-[10px] mb:justify-center ${
          type === "before" ? "mb:h-[168px]" : "mb:h-[188px]"
        }`}
      >
        <div
          className={`w-fit font-bold nomb:text-xl mb:text-lg mb-[8px] tablet:whitespace-pre-line text-center ${
            type === "before" ? "text-white" : "bg-sub"
          }`}
        >
          {title}
        </div>
        <div className="nomb:text-base mb:text-sm text-white tablet:w-[273px] mb:w-[283px] tablet:px-[16px]">
          {content.map((item, idx) => {
            return (
              <div key={idx} className="flex gap-x-1 leading-[160%]">
                <div>•</div>
                <div className="whitespace-pre-line ">{item}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const layout = [
  {
    before: {
      title: `전산 재고와
현물의 불일치`,
      content: [
        `수기 전표에 의존한 입출고 관리로 정보 불일치`,
        `수작업 처리로 인한 정보 등록 지연과 오류 발생 (24시간)`,
      ],
    },
    after: {
      title: `전표의 표준화로
정확한 데이터 관리`,
      content: [
        `공정 바코드 도입을 통한 모든 전표의 표준화`,
        `현물과 전산에 의한 실시간 입출고 시스템 구축`,
      ],
    },
  },
  {
    before: {
      title: `생산진도의
단계별 파악 어려움`,
      content: [
        `수작업 실적집계로 인한 시스템 등록 지연 및 작업자에 의한 수집 오류`,
        `공정간 재고량과 이동 시 LOSS 파악 불가`,
      ],
    },
    after: {
      title: `생산 진도의
단계별 파악 용이`,
      content: [
        `물류 이동 단위에 의한 실시간 생산실적정보 수집`,
        `공정 입출고 정보를 실시간 관리함으로서 라인재고 및 제공의 투명한 파악
(투입 / 실적 / 불량률 / 직행률)`,
      ],
    },
  },
  {
    before: {
      title: `현장의 투명한 파악, 
즉각 대응 어려움`,
      content: [
        `설비 가동 현황 및 비가동 요인 미파악으로 인한 설비 가동률 저하`,
        `체계적 품질관리의 부재로 클레임 시 원인 분석 및 재발 대응 어려움`,
      ],
    },
    after: {
      title: "생산 현황의 명확한 파악",
      content: [
        `설비의 가동률 분석으로 가동률 향상 및 비가동 요인 분석/대응`,
        `품질저해 요인에 대해 통계적 관리기법의 도입.
ISO-9001를 지향하는 품질 향상, 인증 및 외부 감사 수감이 쉬워짐`,
      ],
    },
  },
  {
    before: {
      title: `새로 가동중인 ERP 생산
인프라 요구`,
      content: [
        `ERP 자체구매 업무의 중복 및 누락 발생`,
        `생산 현장 정보의 미반영으로 인한 생산 계획의 차질 우려`,
      ],
    },
    after: {
      title: "ERP 실시간 현장정보 반영",
      content: [
        `ERP에 실시간 현장정보 반영으로 정확한 생산계획 및 구매관리 지원`,
        `생산현장 DATA Upload, Analyzing, Support`,
      ],
    },
  },
];
