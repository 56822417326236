import React from "react";
import { Route, Routes } from "react-router-dom";
import { ResponsiveType } from "../interface/redux/config";
import History from "../pages/About/History";
import Intro from "../pages/About/Intro";
import CiIntro from "../pages/About/CiIntro";

const AboutRoute = (Props: ResponsiveType) => {
  return (
    <Routes>
      <Route path='/intro' element={<Intro Responsive={Props} />} />
      <Route path='/history' element={<History Responsive={Props} />} />
      <Route path='/ci' element={<CiIntro Responsive={Props} />} />
    </Routes>
  );
};

export default AboutRoute;
