import React from "react";
import { Responsiv } from "../../interface/redux/config";

type Props = {
  Mobile: Responsiv;
  PcTablet: Responsiv;
};

const MainSection2 = ({ Mobile, PcTablet }: Props) => {
  return (
    <div className="flex pc:h-[759px] tablet:h-[1404px] pc:pt-[78px] tablet:pt-[88px] mb:pt-[111px] pc:pb-[102px] pc:justify-center pc:gap-x-[174px] bg-black  nopc:flex-col nopc:items-center tablet:gap-y-[110px] mb:gap-y-6">
      <div className="tablet:text-center">
        <div className="bg-main w-fit font-bold nomb:text-xl mb:text-base tablet:m-auto">
          ABOUT
        </div>
        <div className="text-white nomb:text-[32px] mb:text-2xl font-bold leading-[140%] my-3">
          우리는 명실상부한 <br /> 기술 기업으로써의 자부심을 가지고 <br /> 모두
          단결해 나아갈 것입니다
        </div>
        <div className="text-white text-sm font-normal leading-[160%]">
          <PcTablet>
            <>
              안녕하십니까? 휴컨 대표이사 강대근입니다. <br />
              휴컨은 자동차 전장 사업과 이차전지를 활용한 에너지 기술 개발하는
              기업입니다. <br /> 휴컨은 높은 기술력을 강점으로 고객에게 신뢰받는
              회사로 성장하고 있습니다. <br /> 휴컨은 2015년에 임베디드
              엔지니어인 저, 강대근 대표가 창업한 회사입니다. <br /> 높은
              기술력으로 세상을 이롭게 하겠다는 신념으로 시작했지만,
              <br /> 그 시작은 그리 순탄하지 않았습니다. <br /> 하지만, 새로운
              기술과 끈질긴 노력으로 많은 성과를 이루어냈습니다. <br /> 우리는
              이러한 어려움을 이겨낸 DNA가 있습니다. <br />
              이제는 명실상부한 기술 기업으로써 자부심을 가지고 단결해 나아갈
              것입니다.
              <br />
              <br />
              우리 회사는 지속 성장 가능한 미래를 위해 전 임직원이 함께 하고
              있으며,
              <br />
              기업과 가정 모두 행복할 수 있도록 노력하겠습니다.
              <br /> 앞으로 휴컨의 지속적인 발전과 성취에 많은 관심과 격려 부탁
              드립니다. <br />
              <br /> 감사합니다.
            </>
          </PcTablet>
          <Mobile>
            <>
              안녕하십니까? 휴컨 대표이사 강대근입니다. <br />
              휴컨은 자동차 전장 사업과 이차전지를 활용한 에너지 기술 <br />{" "}
              개발하는 기업입니다. 휴컨은 높은 기술력을 강점으로 <br /> 고객에게
              신뢰받는 회사로 성장하고 있습니다. <br />
              <br /> 휴컨은 2015년에 임베디드 엔지니어인 저, 강대근 대표가
              <br />
              창업한 회사입니다. 높은 기술력으로 세상을 이롭게 <br /> 하겠다는
              신념으로 시작했지만, 그 시작은 그리 순탄하지
              <br /> 않았습니다. 하지만, 새로운 기술과 끈질긴 노력으로 많은{" "}
              <br /> 성과를 이루어냈습니다. 우리는 이러한 어려움을 이겨낸
              <br />
              DNA가 있습니다. 이제는 명실상부한 기술 기업으로써 <br /> 자부심을
              가지고 단결해 나아갈 것입니다.
              <br />
              <br />
              우리 회사는 지속 성장 가능한 미래를 위해 전 임직원이
              <br /> 함께 하고 있으며, 기업과 가정 모두 행복할 수 있도록 <br />{" "}
              노력하겠습니다. 앞으로 휴컨의 지속적인 발전과 성취에
              <br /> 많은 관심과 격려 부탁 드립니다. <br />
              <br /> 감사합니다.
            </>
          </Mobile>
        </div>
      </div>
      <div className=" pc:self-end tablet:self-auto mb:w-80">
        <img
          src="/assets/main/section2/main-section2.png"
          srcSet="/assets/main/section2/main-section2@2x.png 2x, /assets/main/section2/main-section2@3x.png 3x"
          alt="대표님"
        />
      </div>
    </div>
  );
};

export default MainSection2;
