import React, { useCallback } from "react";
import { ResponsiveType } from "../../../interface/redux/config";

type Props = {
  Responsive: ResponsiveType;
};

const CiSection3 = ({ Responsive: { Desktop, Tablet, Mobile } }: Props) => {
    const DownLoadAi = useCallback(() => {
    let xhr = new XMLHttpRequest();
    let link = document.createElement("a");
    xhr.responseType = "blob";
    xhr.onload = (event) => {
      var blob = URL.createObjectURL(xhr.response);
      link.href = blob;
      link.download = "[휴컨] 로고.ai";
      link.click();
      link.remove();
    };
    xhr.open("GET", "/file/logo.ai");
    xhr.send();
  }, []);
    const DownLoadPng = useCallback(() => {
    let xhr = new XMLHttpRequest();
    let link = document.createElement("a");
    xhr.responseType = "blob";
    xhr.onload = (event) => {
      var blob = URL.createObjectURL(xhr.response);
      link.href = blob;
      link.download = "[휴컨] 로고.zip";
      link.click();
      link.remove();
    };
    xhr.open("GET", "/file/logo.zip");
    xhr.send();
  }, []);

  return (
    <div className=' pc:w-[1144px] tablet:w-[727px] mb:w-[320px] m-auto'>
      <div className='text-white'>
        <div className='font-bold nomb:text-[40px] mb:text-[32px]'>
          시그니쳐
        </div>
        <div className='text-[14px] font-[400] leading-[160%] mt-[8px]'>
          시그니처는 심벌과 로고타입을 최적의 비례로 조합한 것으로 적용 매체의
          특성에 따라 적절한 시그니처 타입을 선택하여 사용한다. <br />{" "}
          시그니처의 사용은 매뉴얼 파일을 정비례로 확대, 축소하여 시용하도록
          한다.
        </div>
      </div>
      <div className='flex gap-x-[35px] mt-[32px] nopc:flex-col tablet:gap-y-[56px] mb:gap-y-[36px]'>
        <Desktop>
          <>
            <img
              src='/assets/about/ci/content/ci-section3-1.png'
              srcSet='/assets/about/ci/content/ci-section3-1@2x.png 2x, /assets/about/ci/content/ci-section3-1@3x.png 3x'
              alt=''
            />
            <img
              src='/assets/about/ci/content/ci-section3-2.png'
              srcSet='/assets/about/ci/content/ci-section3-2@2x.png 2x, /assets/about/ci/content/ci-section3-2@3x.png 3x'
              alt=''
            />
          </>
        </Desktop>
        <Tablet>
          <>
            <img
              src='/assets/about/ci/content/ci-section3-1-tablet.png'
              srcSet='/assets/about/ci/content/ci-section3-1-tablet@2x.png 2x, /assets/about/ci/content/ci-section3-1-tablet@3x.png 3x'
              alt=''
            />
            <img
              src='/assets/about/ci/content/ci-section3-2-tablet.png'
              srcSet='/assets/about/ci/content/ci-section3-2-tablet@2x.png 2x, /assets/about/ci/content/ci-section3-2-tablet@3x.png 3x'
              alt=''
            />
          </>
        </Tablet>
        <Mobile>
          <>
            <img
              src='/assets/about/ci/content/ci-section3-1-mb.png'
              srcSet='/assets/about/ci/content/ci-section3-1-mb@2x.png 2x, /assets/about/ci/content/ci-section3-1-mb@3x.png 3x'
              alt=''
            />
            <img
              src='/assets/about/ci/content/ci-section3-2-mb.png'
              srcSet='/assets/about/ci/content/ci-section3-2-mb@2x.png 2x, /assets/about/ci/content/ci-section3-2-mb@3x.png 3x'
              alt=''
            />
          </>
        </Mobile>
      </div>
      <div className='flex nomb:gap-x-[16px] nomb:mt-[44px] mb:mt-[36px] justify-center mb:flex-col mb:items-center mb:gap-y-[16px]'>
        <button className='border-solid border-[1px] border-white px-[16px] py-[8px] text-white flex gap-x-[3px] mb:w-[167px] mb:justify-center' onClick={DownLoadAi}>
          AI Download
          <img src='/assets/about/ci/content/down.svg' alt='down' />
        </button>
        <button className='border-solid border-[1px] border-white px-[16px] py-[8px] text-white flex gap-x-[3px] mb:w-[167px] mb:justify-center' onClick={DownLoadPng}>
          Png Download
          <img src='/assets/about/ci/content/down.svg' alt='down' />
        </button>
      </div>
    </div>
  );
};

export default CiSection3;
