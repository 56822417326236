import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import UseResponsive from "./hooks/UseResponsive";
import Contact from "./pages/Contact/Contact";
import Culture from "./pages/Culture/Culture";
import Main from "./pages/Main/Main";
import AboutRoute from "./routes/AboutRoute";
import BusinessRoute from "./routes/BusinessRoute";
import RndRoute from "./routes/RndRoute";
function App() {
  const [Desktop, Mobile, Tablet, TabletMobile, PcTablet] = UseResponsive();
  return (
    <BrowserRouter>
      {/* 헤더 */}
      <Header
        Desktop={Desktop}
        Mobile={Mobile}
        Tablet={Tablet}
        TabletMobile={TabletMobile}
        PcTablet={PcTablet}
      />
      <Routes>
        <Route
          path="/"
          element={
            <Main
              Desktop={Desktop}
              Mobile={Mobile}
              Tablet={Tablet}
              TabletMobile={TabletMobile}
              PcTablet={PcTablet}
            />
          }
        />
        <Route
          path="/about/*"
          element={
            <AboutRoute
              Desktop={Desktop}
              Mobile={Mobile}
              Tablet={Tablet}
              TabletMobile={TabletMobile}
              PcTablet={PcTablet}
            />
          }
        />
        <Route
          path="/rnd/*"
          element={
            <RndRoute
              Desktop={Desktop}
              Mobile={Mobile}
              Tablet={Tablet}
              TabletMobile={TabletMobile}
              PcTablet={PcTablet}
            />
          }
        />
        <Route
          path="/business/*"
          element={
            <BusinessRoute
              Desktop={Desktop}
              Mobile={Mobile}
              Tablet={Tablet}
              TabletMobile={TabletMobile}
              PcTablet={PcTablet}
            />
          }
        />
        <Route
          path="/culture/job"
          element={
            <Culture
              Desktop={Desktop}
              Mobile={Mobile}
              Tablet={Tablet}
              TabletMobile={TabletMobile}
              PcTablet={PcTablet}
            />
          }
        />
        <Route
          path="/contact"
          element={<Contact PcTablet={PcTablet} Mobile={Mobile} />}
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
