import React, { useState } from "react";
import BusinessSubNav from "../../components/Business/BusinessSubNav/BusinessSubNav";
import BusinessTitleSection from "../../components/Business/BusinessTitleSection/BusinessTitleSection";
import { Responsiv } from "../../interface/redux/config";

type Props = {
  Desktop: Responsiv;
  PcTablet: Responsiv;
  Mobile: Responsiv;
};

const Odm = ({ Desktop, PcTablet, Mobile }: Props) => {
  const [NavIndex, setNavIndex] = useState<number | undefined>(undefined);
  return (
    <main className="bg-black">
      <BusinessTitleSection
        Desktop={Desktop}
        PcTablet={PcTablet}
        Mobile={Mobile}
        tag="HUCONN BUSINESS | ODM"
        title={`ODM`}
        sub={`아이디어 제품 형상화, 시제품 제작 지원`}
        mb={`아이디어 제품 형상화, 시제품 제작 지원`}
        bgstyle="pc:bg-odm-title-pc tablet:bg-odm-title-tablet mb:bg-odm-title-mb"
      />
      <BusinessSubNav
        layout={Nav_layout}
        nowNav={NavIndex !== undefined ? NavIndex : 0}
        setNav={setNavIndex}
      />
    </main>
  );
};

export default Odm;
const Nav_layout = ["운용모델", "진행절차", "구축 후 효과"];
