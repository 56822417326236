import React from "react";
import PageTitleSection from "../../../components/Common/PageTitleSection";
import { Responsiv } from "../../../interface/redux/config";
import RndContentSection from "../../../components/Rnd/RndContentSection";

type Props = {
  Desktop: Responsiv;
  PcTablet: Responsiv;
  Mobile: Responsiv;
};

const RndEnergy = ({ Desktop, PcTablet, Mobile }: Props) => {
  return (
    <main>
      <PageTitleSection
        title={`ENERGY
DEVELOPMENT`}
        sub={`HUCONN R&D | 에너지 개발`}
        bgClass='pc:bg-energytitle tablet:bg-energytitle-tablet mb:bg-energytitle-mb'
        Desktop={Desktop}
      />
      <RndContentSection
        PcTablet={PcTablet}
        Mobile={Mobile}
        layout={layout}
        sub={
          <>
            중소기업 최초로 인증 받은 BMS 기능안전설계 기술과 <br />
            무선 통신 기술을 이용하여 다양한 배터리 관련 응용 제품 개발을
            수행합니다
          </>
        }
        title='에너지 개발'
      />
    </main>
  );
};

export default RndEnergy;

const layout = [
  {
    number: "01",
    title: "BMS 배터리 관리 시스템",
    pctablet: `IEC 61508, IEC 62619 국제 표준을 기준으로 기능안전 설계가 적용된
배터리 관리 시스템 솔루션으로 분산형 전원, 분산에너지 분야에서 고객들에게
고안전성을 보장합니다.`,
    mb: `IEC 61508, IEC 62619 국제 표준을 기준으로
기능안전 설계가 적용된 배터리 관리 시스템 솔루션으로 분산형 전원, 분산에너지 분야에서 고객들에게
고안전성을 보장합니다.`,
    bg: "pc:bg-energycontentpc1 tablet:bg-energycontenttablet1 mb:bg-energycontentmb1",
  },
  {
    number: "02",
    title: "이동형 전기차 충전 시스템",
    pctablet: `재사용 배터리를 이용한 ESS 기반 이동형 전기차 충전기 시스템 개발을 통해
기존 충전기의 한계를 극복하고 사용자의 편의성과 안전성을 높인 충전 서비스를 제공합니다.`,
    mb: `재사용 배터리를 이용한 ESS 기반 
이동형 전기차 충전기 시스템 개발을 통해
기존 충전기의 한계를 극복하고 사용자의 편의성과
안전성을 높인 충전 서비스를 제공합니다.`,
    bg: "pc:bg-energycontentpc2 tablet:bg-energycontenttablet2 mb:bg-energycontentmb2",
  },
  {
    number: "03",
    title: "ESS 에너지 저장 시스템",
    pctablet: `자사의 핵심기술인 기능안전설계 기반의 배터리관리시스템 설계 기술과 무선 통신 기술을
이용하여 레저 및 캠핑용 소형 ESS인 ENERSTATION E1 제품 개발을 진행하였습니다.`,
    mb: `자사의 핵심기술인 기능안전설계 기반의
배터리관리시스템 설계 기술과 무선 통신 기술을
이용하여 레저 및 캠핑용 소형 ESS인 
ENERSTATION E1 제품 개발을 진행하였습니다.`,
    bg: "pc:bg-energycontentpc3 tablet:bg-energycontenttablet3 mb:bg-energycontentmb3",
  },
  {
    number: "04",
    title: "배터리 에뮬레이터",
    pctablet: `휴컨의 배터리 에뮬레이터는 배터리의 특성 파악 및 응용
제품 개발이 용이하도록 손 쉬운 배터리 테스트 환경을 제공합니다.`,
    mb: `휴컨의 배터리 에뮬레이터는 배터리의 특성
파악 및 응용 제품 개발이 용이하도록 
손 쉬운 배터리 테스트 환경을 제공합니다.`,
    bg: "pc:bg-energycontentpc4 tablet:bg-energycontenttablet4 mb:bg-energycontentmb4",
  },
  {
    number: "05",
    title: "소형 e-모빌리티용 배터리팩",
    pctablet: `휴컨의 차별화된 BMS 설계 기술과 무선 통신 기반의 배터리 수명 예측 알고리즘이 적용된
소형 e-모빌리티용 배터리팩을 통해 관련 제조사에 보다 안전하고 효율적인 제품을 제공합니다. `,
    mb: `휴컨의 차별화된 BMS 설계 기술과 무선 통신 기반의
배터리 수명 예측 알고리즘이 적용된 소형 e-모빌리티용
배터리팩을 통해 관련 제조사에 보다
안전하고 효율적인 제품을 제공합니다. `,
    bg: "pc:bg-energycontentpc5 tablet:bg-energycontenttablet5 mb:bg-energycontentmb5",
  },
];
