import React, { Dispatch, SetStateAction } from "react";

type Props = {
  layout: string[];
  nowNav: number;
  setNav: Dispatch<SetStateAction<number | undefined>>;
};

const BusinessSubNav = ({ layout, nowNav, setNav }: Props) => {
  return (
    <div className="w-full h-[65px] bg-gray600 flex justify-center nopc:border-b-gray400 nopc:border-b-[1px] sticky top-[66px]">
      <div className="flex pc:border-b-gray400 pc:border-b-[1px] relative">
        {layout.map((item, idx) => {
          return (
            <button
              key={idx}
              className={`nomb:w-[189px] mb:w-[110px] nomb:text-lg mb:text-sm font-bold  ${
                nowNav === idx ? "text-mint" : "text-gray200"
              }`}
              onClick={() => {
                setNav(idx);
              }}
            >
              {item}
            </button>
          );
        })}
        <div
          className={`absolute h-[4px] nomb:w-[189px] mb:w-[110px] bottom-[-2px] bg-mint transition-all`}
          style={{ left: `${(nowNav / 3) * 100}%` }}
        />
      </div>
    </div>
  );
};

export default BusinessSubNav;
