import React from "react";

type Props = {};

const CiColor = (props: Props) => {
  return (
    <div className='text-white pc:w-[1143px] tablet:w-[727px] mb:w-[320px] m-auto pc:mt-[182px] tablet:mt-[241px] mb:mt-[228px] pc:pb-[228px] tablet:pb-[286px] mb:pb-[280px]'>
      <div>
        <div className='font-bold nomb:text-[40px] mb:text-[32px]'>
          전용색상
        </div>
        <div className='text-[14px] leading-[160%] font-[400] mt-[7px]'>
          전용색상은 심벌. 로고타입과 더불어 제일 CI의 핵심이 되는 기본요소로써
          제일의 기업이념을 잘 전달할 수 있도록 선택된 색상이다. 전용색상의
          표현은 적용매체의 특성 및 상황에 따라 별색 인쇄 혹은 4원색 프로세스
          인쇄방식으로 표현할 수 있다. 색상 표현에 있어 매체의 성격에 따라 색상
          재현에 미세한 차이가 있을 수 있으나 예시된 색상에 가장 근접한 표현이
          되도록 유의한다.
        </div>
      </div>
      <div className='flex gap-x-[35px] mt-[34px] mb:flex-col mb:gap-y-[48px]'>
        {colorLayouts.map(({ title, cmyk, rgb, hex }, idx) => {
          return (
            <div key={idx}>
              <div
                className={`${
                  idx ? "bg-[#1C49FF]" : "bg-mint"
                } pc:w-[555px] tablet:w-[354px]  h-[53px] `}
              />
              <div className='text-[18px] font-bold mt-[8px]'>{title}</div>
              <div className='flex gap-x-[17px] text-[14px] mt-[4px]'>
                <div className='w-[40px]'>CMYK</div>
                <div>{cmyk}</div>
              </div>
              <div className='flex gap-x-[17px] text-[14px]'>
                <div className='w-[40px]'>RGB</div>
                <div>{rgb}</div>
              </div>
              <div className='flex gap-x-[17px] text-[14px]'>
                <div className='w-[40px]'>HEX</div>
                <div>{hex}</div>
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <div className='w-full h-[53px] bg-chip pc:mt-[95px] tablet:mt-[70px] mb:mt-[48px]' />
        <div className='text-[18px] font-bold mt-[8px]'>HUCONN Gradation</div>
      </div>
    </div>
  );
};

export default CiColor;

const colorLayouts = [
  {
    title: "HUCONN Mint",
    cmyk: "C63 M0 Y38 K0",
    rgb: "R11 G228 B198",
    hex: "#0BE4C6",
  },
  {
    title: "HUCONN Blue",
    cmyk: "C87 M67 Y0 K0",
    rgb: "R28 G73 B255",
    hex: "#1C49FF",
  },
];
