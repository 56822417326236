import React from "react";
import MainSection1 from "../../components/Main/MainSection1";
import MainSection2 from "../../components/Main/MainSection2";
import MainSection3 from "../../components/Main/MainSection3";
import MainSection4 from "../../components/Main/MainSection4";
import MainSection5 from "../../components/Main/MainSection5";
import MainSection6 from "../../components/Main/MainSection6";
import { ResponsiveType } from "../../interface/redux/config";

const Main = ({ Mobile, PcTablet }: ResponsiveType) => {
  return (
    <main className="bg-black h-fit">
      <MainSection1 />
      <MainSection2 PcTablet={PcTablet} Mobile={Mobile} />
      <MainSection3 PcTablet={PcTablet} />
      <MainSection4 />
      <MainSection5 Mobile={Mobile} />
      <div className="pc:h-[625px] tablet:h-[938px] mb:h-[802px]">
        <MainSection6 style="pc:pt-[184px] tablet:pt-[182px] mb:pt-[211px]" />
      </div>
    </main>
  );
};

export default Main;
