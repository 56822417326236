import React from "react";
import { ResponsiveType } from "../../interface/redux/config";

type Props = {
  responsive: ResponsiveType;
};

const CultureSection2 = ({
  responsive: { Desktop, Tablet, Mobile, PcTablet },
}: Props) => {
  return (
    <div className='text-white nomb:mt-[114px] mb:mt-[200px]'>
      <div className='text-center nomb:text-[32px] mb:text-[30px] font-bold  leading-[140%]'>
        휴컨은 <br />
        보다 안전한, 보다 편리한, 보다 혁신적인{" "}
        <PcTablet>
          <br />
        </PcTablet>
        기술을 추구하는 기업입니다.
      </div>
      <div className='text-center leading-[160%] text-[14px] mt-[16px]'>
        기술 융합 시대에 있어 중요한 성장 분야인{" "}
        <Mobile>
          <br />
        </Mobile>{" "}
        에너지와 자동차를 기반으로
        <br />
        지속적인 연구 개발을 하고 있으며,{" "}
        <Mobile>
          <br />
        </Mobile>{" "}
        우리의 기술이 생활 속에 자리 매김하여
        <br />
        사람들에게 감동을 주는 것을 목표로 삼고 있습니다.
      </div>
      <div className='grid nomb:grid-cols-2 gap-y-[80px] pc:gap-x-[32px] tablet:gap-x-[16px]  mt-[40px]'>
        {cultureSection2Layouts.map(({ eng, kor, sub, img }, idx) => {
          return (
            <div key={idx}>
              <Desktop>
                <img
                  src={`/assets/culture/content/${img}.png`}
                  srcSet={`/assets/culture/content/${img}@2x.png 2x, /assets/culture/content/${img}@3x.png 3x`}
                  alt=''
                />
              </Desktop>
              <Tablet>
                <img
                  src={`/assets/culture/content/${img}-tablet.png`}
                  srcSet={`/assets/culture/content/${img}-tablet@2x.png 2x, /assets/culture/content/${img}-tablet@3x.png 3x`}
                  alt=''
                />
              </Tablet>
              <Mobile>
                <img
                  src={`/assets/culture/content/${img}-tablet.png`}
                  srcSet={`/assets/culture/content/${img}-tablet@2x.png 2x, /assets/culture/content/${img}-tablet@3x.png 3x`}
                  alt=''
                />
              </Mobile>
              <div className='font-bold  pc:text-[24px] nopc:text-[20px] leading-[140%] mt-[24px]  pc:mb-[16px] nopc:mb-[5px]'>
                {eng}
                <br />
                {kor}
              </div>
              <div className=' whitespace-pre-line  text-[14px] leading-[160%]'>
                {sub}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CultureSection2;

const cultureSection2Layouts = [
  {
    eng: `ENERGY DEVELOPMENT`,
    kor: "에너지 개발",
    sub: `안전 설계 및 무선 통신 기술을 이용한 ESS와 
이동형 충전 배터리 개발을 수행합니다.`,
    img: "culture-section2-1",
  },
  {
    eng: `CAR INFOTAINMENT DEVELOPMENT`,
    kor: "자동차 전장 개발",
    sub: `자동차 전장, 특히 인포테인먼트와 차량 내
네트워크 기술 개발을 수행합니다.`,
    img: "culture-section2-2",
  },
  {
    eng: `SMART SOLUTION`,
    kor: "스마트 솔루션",
    sub: `효율적인 스마트 공장 및 스마트 팜을 위한 자동화 설비 
개발 및 MES, ERP, SCM 등 적합한 SOLUTION 제공`,
    img: "culture-section2-3",
  },
  {
    eng: `EDUCATION SOLUTION`,
    kor: "교육 솔루션",
    sub: `신설 학과 및 학과 개편 컨설팅,
교육 훈련 장비 기획 및 제조, 교육 지원 제공`,
    img: "culture-section2-4",
  },
];
