import React from "react";
import { Responsiv } from "../../../interface/redux/config";

type Props = {
  PcTablet: Responsiv;
  Mobile: Responsiv;
};

const IntroSection1 = ({ PcTablet, Mobile }: Props) => {
  return (
    <div className='pc:pt-[199px] tablet:pt-[228px] mb:pt-[108px] pc:w-[1144px] tablet:w-[658px] mb:w-[365px]  m-auto pc:h-[603px] relative'>
      <div className='tablet:text-center'>
        <div className='text-white nomb:text-[40px] mb:text-2xl font-bold mb:px-[14px]'>
          휴컨은 사람과 미래를 위해 <br />
          기술을 연구합니다
        </div>
        <PcTablet>
          <div className='text-white text-[13px] mt-7 leading-[160%]'>
            휴컨은 <span className='font-bold'>에너지</span>와{" "}
            <span className='font-bold'>자동차 전장 분야</span>를 연구하는
            기업입니다. <br /> 에너지와 자동차는 기술 융합 시대에 있어 중요한
            분야 중 하나입니다. <br />
            휴컨은 에너지 분야에서는 안전성이 높은 BMS와 배터리 제품을 개발하고
            있으며, <br /> 자동차 분야에서는 인포테인먼트, 차량 내 네트워크 등의
            첨단 자동차 부품을 연구하고 있습니다. <br /> 휴컨은 이 두가지 분야가
            하나의 제품으로 만들어지는 과정을 함께 바라보고 있습니다. <br />
            <br />
            <span className='font-bold'>
              앞으로 분산형 에너지 산업과 첨단 미래 자동차 분야에서 <br />{" "}
              최고의 기업이 되는 것을 목표로 연구와 기술 개발에 힘쓰고 있습니다.
            </span>
          </div>
        </PcTablet>
        <Mobile>
          <div className='text-white text-[14px] mt-[31px] leading-[160%] px-[14px]'>
            휴컨은 <span className='font-bold'>에너지</span>와{" "}
            <span className='font-bold'>자동차 전장 분야</span>를 연구하는
            기업입니다. <br /> 에너지와 자동차는 기술 융합 시대에 있어 중요한
            분야 중 <br /> 하나입니다. 휴컨은 에너지 분야에서는 안전성이 높은
            <br />
            BMS와 배터리 제품을 개발하고 있으며, <br /> 자동차 분야에서는
            인포테인먼트, 차량 내 네트워크 등의 <br />
            첨단 자동차 부품을 연구하고 있습니다. <br /> 휴컨은 이 두가지 분야가
            하나의 제품으로 만들어지는 과정을
            <br /> 함께 바라보고 있습니다. <br />
            <br />
            <span className='font-bold'>
              앞으로 분산형 에너지 산업과 첨단 미래 자동차 분야에서 <br />{" "}
              최고의 기업이 되는 것을 목표로 연구와 기술 개발에 <br /> 힘쓰고
              있습니다.
            </span>
          </div>
        </Mobile>
      </div>
      <div className='pc:w-[835px] mb:w-[365px] pc:h-[603px] pc:bg-main pc:absolute pc:right-[-400px] pc:top-0 tablet:mt-[69px]'>
        <img
          className='pc:absolute nopc:relative z-10 pc:top-[-78px] pc:left-[-278px] pc:z-10 pc:w-[979px] pc:max-w-none'
          src='/assets/about/intro/section1/intro-car.png'
          srcSet='/assets/about/intro/section1/intro-car@2x.png 2x, /assets/about/intro/section1/intro-car@3x.png 3x'
          alt='car'
        />
        <img
          className='pc:w-[616px] tablet:w-[405px] mb:w-[247px] absolute pc:right-[300px] pc:bottom-[-500px] tablet:bottom-[-155px] mb:bottom-[-95px] pc:rotate-90'
          src='/assets/about/intro/circle.png'
          srcSet='/assets/about/intro/circle@2x.png 2x, /assets/about/intro/circle@3x.png 3x'
          alt='circle'
        />
      </div>
    </div>
  );
};

export default IntroSection1;
