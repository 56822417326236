import React from "react";

import { ResponsiveType } from "../../interface/redux/config";
import CultureSection1 from "../../components/Culture/CultureSection1";
import CultureSection2 from "../../components/Culture/CultureSection2";
import CultureSection3 from "../../components/Culture/CultureSection3";
import CultureSection4 from "../../components/Culture/CultureSection4";
import CultureSection5 from "../../components/Culture/CultureSection5";

const Culture = (props: ResponsiveType) => {
  return (
    <main className='pt-[66px] bg-black pc:w-[1148px] tablet:w-[728px] mb:w-[320px] m-auto'>
      <CultureSection1 responsive={props} />
      <CultureSection2 responsive={props} />
      <CultureSection3 responsive={props} />
      <CultureSection4 responsive={props} />
      <CultureSection5 responsive={props} />
    </main>
  );
};

export default Culture;
