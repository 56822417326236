import React from "react";
import { Responsiv } from "../../../interface/redux/config";

type Props = {
  Desktop: Responsiv;
  Mobile: Responsiv;
  Tablet: Responsiv;
  PcTablet: Responsiv;
};

const IntroSection2 = ({ Desktop, Mobile, Tablet, PcTablet }: Props) => {
  return (
    <div className="pc:pt-[60px] tablet:pt-[76px] mb:py-[250px] pb-[134px]">
      <div className="pc:bg-introsection2pc tablet:bg-introsection2tablet pc:w-[1144px] tablet:w-[688px] nomb:h-[1048px] m-auto bg-contain bg-no-repeat grid relative mb:grid-cols-[328px] mb:justify-center mb:gap-y-[30px]">
        <PcTablet>
          <>
            <img
              className="absolute pc:w-[405px] tablet:w-[281px] pc:left-[-120px] tablet:right-[-125px] tablet:top-[133px] pc:top-[151px] tablet:rotate-90"
              src="/assets/about/intro/circle.png"
              srcSet="/assets/about/intro/circle@2x.png 2x, /assets/about/intro/circle@3x.png 3x"
              alt=""
            />
            <img
              className="absolute w-[400px] pc:bottom-[100px] tablet:bottom-[-81px] tablet:left-[127px] pc:right-9  "
              src="/assets/about/intro/circle2.png"
              srcSet="/assets/about/intro/circle2@2x.png 2x, /assets/about/intro/circle2@3x.png 3x"
              alt=""
            />
          </>
        </PcTablet>
        {layout.map(({ tag, title, pc, tablet, mb }, idx) => {
          return (
            <div
              key={idx}
              className={`pc:pt-[76px] tablet:pt-[63px] pc:pl-[46px] tablet:pl-[20px] pc:w-[572px] tablet:w-[343px] nomb:h-[350px] mb:h-[321px] mb:bg-gradient mb:p-[3px]  relative z-10 ${
                idx === 1 ? "nomb:justify-self-end" : ""
              }`}
            >
              <div className="w-full h-full mb:bg-black mb:text-center mb:pt-[35px]">
                <span className="text-sm font-bold bg-main">{tag}</span>
                <div className="my-1 text-2xl font-bold text-white whitespace-pre-line">
                  {title}
                </div>
                <Desktop>
                  <div className="text-sm text-white whitespace-pre-line leading-[160%]">
                    {pc}
                  </div>
                </Desktop>
                <Tablet>
                  <div className="text-sm text-white whitespace-pre-line leading-[160%]">
                    {tablet}
                  </div>
                </Tablet>
                <Mobile>
                  <div className="text-sm text-white whitespace-pre-line leading-[160%]">
                    {mb}
                  </div>
                </Mobile>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default IntroSection2;

const layout = [
  {
    tag: "FUNCTION 01",
    title: `휴컨이 가진 기술로
인류애를 실현하고자 합니다`,
    pc: `기업이 추구해야할 여러 가치 중 보다 나은 인간의 삶을 위한 기술에 
집중합니다. 보다 안전한 기술, 보다 편리한 기술, 보다 혁신적인 
기술을 추구 합니다. 우리의 기술이 생활속에 자리 매김하여 
사람들에게 감동을 주는 것이 목표입니다.`,
    tablet: `기업이 추구해야할 여러 가치 중 보다 나은 인간의 삶을 
위한 기술에 집중합니다. 보다 안전한 기술, 보다 편리한 
기술, 보다 혁신적인 기술을 추구 합니다. 
우리의 기술이 생활속에 자리 매김하여 
사람들에게 감동을 주는 것이 목표입니다.`,
    mb: `기업이 추구해야할 여러 가치 중 보다 나은 인간의 삶을 
위한 기술에 집중합니다. 보다 안전한 기술, 보다 편리한 
기술, 보다 혁신적인 기술을 추구 합니다. 
우리의 기술이 생활속에 자리 매김하여 
사람들에게 감동을 주는 것이 목표입니다.`,
  },
  {
    tag: "FUNCTION 02",
    title: `휴컨은
기술 중심적 회사입니다.`,
    pc: `휴컨은 보유한 기술을 고도화 시키는 동시에,
변화하는 기술에 따라 새로운 기술을 끊임없이 추구합니다.
주요 기술은 인포테인먼트, 차량내 네트워크 기술 등의 자동차 전장 기술과
에너지 분야의 핵심인 배터리용 BMS 기술, 배터리 팩 제조 기술
그리고 이 모든 것의 근간을 이루는 임베디드 시스템 기술입니다.`,
    tablet: `휴컨은 보유한 기술을 고도화 시키는 동시에,
변화하는 기술에 따라 새로운 기술을 끊임없이 추구합니다.
주요 기술은 인포테인먼트, 차량내 네트워크 기술 등의 
자동차 전장 기술과 에너지 분야의 핵심인 배터리용 
BMS 기술, 배터리 팩 제조 기술 그리고 이 모든 것의 
근간을 이루는 임베디드 시스템 기술입니다.`,
    mb: `휴컨은 보유한 기술을 고도화 시키는 동시에,
변화하는 기술에 따라 새로운 기술을 끊임없이 
추구합니다. 주요 기술은 인포테인먼트, 차량내 
네트워크 기술 등의 자동차 전장 기술과 에너지 
분야의 핵심인 배터리용 BMS 기술, 
배터리 팩 제조 기술 그리고 이 모든 것의 
근간을 이루는 임베디드 시스템 기술입니다.`,
  },
  {
    tag: "FUNCTION 03",
    title: `휴컨은
인재를 중요시합니다.`,
    pc: `기업과 개인의 삶이 함께 성장하기를 바랍니다.
이를 위해서 보다 나은 급여 수준, 착실히 준비중인 복지 지원
성과에 따른 인센티브 등을 제공합니다.`,
    tablet: `기업과 개인의 삶이 함께 성장하기를 바랍니다.
이를 위해서 보다 나은 급여 수준, 착실히 준비중인 
복지 지원 성과에 따른 인센티브 등을 제공합니다.`,
    mb: `기업과 개인의 삶이 함께 성장하기를 바랍니다.
이를 위해서 보다 나은 급여 수준, 착실히 준비중인 
복지 지원 성과에 따른 인센티브 등을 제공합니다.`,
  },
];
