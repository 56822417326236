import React, { useCallback } from "react";
import { useMediaQuery } from "react-responsive";

type ReturnType = [
  ({ children }: { children: JSX.Element }) => JSX.Element | null,
  ({ children }: { children: JSX.Element }) => JSX.Element | null,
  ({ children }: { children: JSX.Element }) => JSX.Element | null,
  ({ children }: { children: JSX.Element }) => JSX.Element | null,
  ({ children }: { children: JSX.Element }) => JSX.Element | null
];

const UseResponsive = (): ReturnType => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1439 });
  const isDesktop = useMediaQuery({ minWidth: 1440 });

  const Desktop = useCallback(
    ({ children }: { children: JSX.Element }) => {
      return isDesktop ? children : null;
    },
    [isDesktop]
  );
  const Tablet = useCallback(
    ({ children }: { children: JSX.Element }) => {
      return isTablet ? children : null;
    },
    [isTablet]
  );
  const Mobile = useCallback(
    ({ children }: { children: JSX.Element }) => {
      return isMobile ? children : null;
    },
    [isMobile]
  );
  const TabletMobile = useCallback(
    ({ children }: { children: JSX.Element }) => {
      return isMobile || isTablet ? children : null;
    },
    [isMobile, isTablet]
  );
  const PcTablet = useCallback(
    ({ children }: { children: JSX.Element }) => {
      return isDesktop || isTablet ? children : null;
    },
    [isDesktop, isTablet]
  );
  return [Desktop, Mobile, Tablet, TabletMobile, PcTablet];
};

export default UseResponsive;
