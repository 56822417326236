import React from "react";
import HistoryContentSection from "../../components/About/History/HistoryContentSection";
import PartnerSection from "../../components/About/History/PartnerSection";
import PageTitleSection from "../../components/Common/PageTitleSection";
import { ResponsiveType } from "../../interface/redux/config";

type Props = {
  Responsive: ResponsiveType;
};

const History = ({
  Responsive: { PcTablet, Mobile, Desktop, Tablet, TabletMobile },
}: Props) => {
  return (
    <main className='pt-[66px] bg-black'>
      <PageTitleSection
        title={`COMPANY
INTRODUCTION`}
        sub={`끊임없는 연구와 발전으로
이루어진 휴컨의 연혁`}
        bgClass='pc:bg-historytitle tablet:bg-historytitle-tablet
        mb:bg-historytitle-mb
        '
        Desktop={Desktop}
      />
      <HistoryContentSection
        Desktop={Desktop}
        Tablet={Tablet}
        Mobile={Mobile}
      />
      <PartnerSection Desktop={Desktop} Tablet={Tablet} Mobile={Mobile} />
    </main>
  );
};

export default History;
