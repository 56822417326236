import React from "react";
import IntroSection1 from "../../components/About/Intro/IntroSection1";
import IntroSection2 from "../../components/About/Intro/IntroSection2";
import IntroSection3 from "../../components/About/Intro/IntroSection3";
import { ResponsiveType } from "../../interface/redux/config";

type Props = {
  Responsive: ResponsiveType;
};

const Intro = ({
  Responsive: { PcTablet, Mobile, Desktop, Tablet },
}: Props) => {
  return (
    <main className="w-full min-h-full bg-black">
      <IntroSection1 PcTablet={PcTablet} Mobile={Mobile} />
      <IntroSection2
        Mobile={Mobile}
        Desktop={Desktop}
        Tablet={Tablet}
        PcTablet={PcTablet}
      />
      <IntroSection3 />
    </main>
  );
};

export default Intro;
