import React from "react";
import { ResponsiveType } from "../../interface/redux/config";

type Props = {
  responsive: ResponsiveType;
};

const CultureSection4 = ({
  responsive: { Desktop, Tablet, Mobile, PcTablet },
}: Props) => {
  return (
    <div className='mt-[240px]'>
      <div className='text-white nomb:text-[40px] mb:text-[30px] leading-[140%] font-bold'>
        휴컨은, <br /> 열정적인 당신을 위해{" "}
        <Mobile>
          <br />
        </Mobile>{" "}
        끊임없이 지원합니다.
      </div>
      <Desktop>
        <img
          className='mt-[140px]'
          src='/assets/culture/content/culture-section4-main.png'
          srcSet='/assets/culture/content/culture-section4-main@2x.png 2x, /assets/culture/content/culture-section4-main@3x.png 3x'
          alt=''
        />
      </Desktop>
      <Tablet>
        <img
          className='mt-[124px]'
          src='/assets/culture/content/culture-section4-main-tablet.png'
          srcSet='/assets/culture/content/culture-section4-main-tablet@2x.png 2x, /assets/culture/content/culture-section4-main-tablet@3x.png 3x'
          alt=''
        />
      </Tablet>
      <Mobile>
        <img
          className='mt-[36px]'
          src='/assets/culture/content/culture-section4-main-mb.png'
          srcSet='/assets/culture/content/culture-section4-main-mb@2x.png 2x, /assets/culture/content/culture-section4-main-mb@3x.png 3x'
          alt=''
        />
      </Mobile>
      <div className='grid gap-y-[100px] mt-[124px]'>
        {cultureSection4Layouts.map(({ title, content, img, mb }, idx) => {
          return (
            <React.Fragment key={idx}>
              {idx === 5 && (
                <div className='text-white text-center nomb:text-[48px] mb:text-[36px] font-bold pc:my-[22px] tablet:my-[40px] mb:my-[26px]'>
                  and for you
                </div>
              )}
              <div className='flex mb:flex-col pc:gap-x-[48px] mb:gap-y-[24px] tablet:gap-x-[16px] nomb:items-center '>
                <Desktop>
                  <img
                    src={`/assets/culture/content/${img}.png`}
                    srcSet={`/assets/culture/content/${img}@2x.png 2x, /assets/culture/content/${img}@3x.png 3x`}
                    alt=''
                  />
                </Desktop>
                <Tablet>
                  <img
                    src={`/assets/culture/content/${img}-tablet.png`}
                    srcSet={`/assets/culture/content/${img}-tablet@2x.png 2x, /assets/culture/content/${img}-tablet@3x.png 3x`}
                    alt=''
                  />
                </Tablet>
                <Mobile>
                  <img
                    src={`/assets/culture/content/${img}-mb.png`}
                    srcSet={`/assets/culture/content/${img}-mb@2x.png 2x, /assets/culture/content/${img}-mb@3x.png 3x`}
                    alt=''
                  />
                </Mobile>
                <div className='text-white'>
                  <div className='font-bold nomb:text-[32px] mb:text-[28px]'>
                    {title}
                  </div>
                  <PcTablet>
                    <div className='mt-[16px] text-[16px] leading-[160%] whitespace-pre-line'>
                      {content}
                    </div>
                  </PcTablet>
                  <Mobile>
                    <div className='mt-[16px] text-[16px] leading-[160%] whitespace-pre-line'>
                      {mb}
                    </div>
                  </Mobile>
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default CultureSection4;

const cultureSection4Layouts = [
  {
    title: "건강검진 비용 지원",
    content: `휴컨은 20만원 상당의 건강 검진 비용 지원을 통해
휴컨 구성원 모두가 건강한 몸과 마음으로 일에
집중할 수 있도록 지원합니다 : )

휴컨을 위해 노력해주는 당신에게 조금 더 보답하기 위해
끊임없이 고민합니다.`,
    mb: `휴컨은 20만원 상당의 건강 검진 비용 지원을 통해
휴컨 구성원 모두가 건강한 몸과 마음으로 일에
집중할 수 있도록 지원합니다 : )

휴컨을 위해 노력해주는 당신에게 조금 더 보답하기 위해 끊임없이 고민합니다.`,
    img: "culture-section4-1",
  },
  {
    title: "생일을 위한 특별한 지원",
    content: `" 생일 축하한DAY " 를 통해 생일 당일 오후 반차와 더불어
5만원 상당의 생일 선물을 지원합니다 : )

특별한 날을 더 특별하게 보낼 수 있도록
더욱 더 특별하게 지원합니다.`,
    mb: `" 생일 축하한DAY " 를 통해 생일 당일 오후 반차와 더불어 5만원 상당의 생일 선물을 지원합니다 : )

특별한 날을 더 특별하게 보낼 수 있도록
더욱 더 특별하게 지원합니다.`,
    img: "culture-section4-2",
  },
  {
    title: "행복개발비 지원",
    content: `휴컨 구성원의 자기계발 및 성장을 위해 개인 맞춤
주변기기 구매가 가능하도록 20만원 상당의 비용을
지원해주는 제도입니다 : )

의미가 있는 활동 그리고 성장을 위한 지원을 통해
보다 행복을 느낄 수 있도록 합
니다.`,
    mb: `휴컨 구성원의 자기계발 및 성장을 위해 개인 맞춤
주변기기 구매가 가능하도록 20만원 상당의 비용을
지원해주는 제도입니다 : )

의미가 있는 활동 그리고 성장을 위한 지원을 통해
보다 행복을 느낄 수 있도록 합니다.`,
    img: "culture-section4-3",
  },
  {
    title: "도서구매 지원",
    content: `휴컨은 업무 관련 도서 구매 시 금액과 수량
상관없이 얼마든지 지원합니다 : )

더 나은 발전과 성장을 향해 노력하는
휴컨 구성원을 위해 끊임없이 지원합니다.`,
    mb: `휴컨은 업무 관련 도서 구매 시 금액과 수량
상관없이 얼마든지 지원합니다 : )

더 나은 발전과 성장을 향해 노력하는
휴컨 구성원을 위해 끊임없이 지원합니다.`,
    img: "culture-section4-4",
  },
  {
    title: "선택적 근로시간제",
    content: `휴컨은 정해진 집중 코어타임 (10시~16시) 외
자유로운 출퇴근을 허용하여 구성원의 워라벨을 지원합니다 : )

신뢰를 바탕으로 구성원들의 일과 가정의 양립을 지원합니다.`,
    mb: `휴컨은 정해진 집중 코어타임 (10시~16시) 외
자유로운 출퇴근을 허용하여 구성원의 워라벨을
지원합니다 : )

신뢰를 바탕으로 구성원들의 일과 가정의
양립을 지원합니다.`,
    img: "culture-section4-5",
  },
  {
    title: "WELCOME DRINK 제공",
    content: `휴컨에 지원해주신 여러분에게 작지만
소중한 선물을 제공합니다 : )

비록 한잔의 차지만 서로에 대해 알아갈 수 있는
뜻 깊은 시간을 마련합니다.`,
    mb: `휴컨에 지원해주신 여러분에게 작지만
소중한 선물을 제공합니다 : )

비록 한잔의 차지만 서로에 대해 알아갈 수 있는
뜻 깊은 시간을 마련합니다.`,
    img: "culture-section4-6",
  },
];
