import React from "react";
import PageTitleSection from "../../../components/Common/PageTitleSection";
import { Responsiv } from "../../../interface/redux/config";
import RndContentSection from "../../../components/Rnd/RndContentSection";

type Props = {
  Desktop: Responsiv;
  PcTablet: Responsiv;
  Mobile: Responsiv;
};

const RndCar = ({ Desktop, PcTablet, Mobile }: Props) => {
  return (
    <main>
      <PageTitleSection
        title={`CAR 
INFOTAIMENT`}
        sub={`HUCONN R&D | 자동차 전장`}
        bgClass=' pc:bg-cartitle tablet:bg-cartitle-tablet mb:bg-cartitle-mb'
        Desktop={Desktop}
      />
      <RndContentSection
        PcTablet={PcTablet}
        Mobile={Mobile}
        layout={layout}
        title=' 자동차 전장 개발'
        sub={
          <>
            자동차 전장, 특히 인포테인먼트와
            <br /> 차량내 네트워크 기술 개발을 수행합니다.
          </>
        }
      />
    </main>
  );
};

export default RndCar;
const layout = [
  {
    number: "01",
    title: "자동차 전장 제품",
    pctablet: `휴컨은 자동차 전장 분야에서 풍부한 경험과 빠른 대응력으로
현대, 쌍용, HUMAX , 인도 마힌드라 등 국내외 기업들과의 협력관계에 있습니다.`,
    mb: `휴컨은 자동차 전장 분야에서 풍부한 경험과
빠른 대응력으로 현대, 쌍용, HUMAX , 인도 마힌드라 등 국내외 기업들과의 협력관계에 있습니다.`,
    bg: "pc:bg-carcontentpc1 tablet:bg-carcontenttablet1 mb:bg-carcontentmb1",
  },
  {
    number: "02",
    title: "오픈 플랫폼",
    pctablet: `휴컨은 Telechips 등 다양한 협력기업과의 협업을 통해
차량용 시스템 반도체를 이용한 오픈플랫폼을 기술 개발하고 있습니다.`,
    mb: `휴컨은 Telechips 등 다양한 협력기업과의 협업을 통해
차량용 시스템 반도체를 이용한 오픈플랫폼을
기술 개발하고 있습니다.`,
    bg: "pc:bg-carcontentpc2 tablet:bg-carcontenttablet2 mb:bg-carcontentmb2",
  },
  {
    number: "03",
    title: "AUTOSAR",
    pctablet: `휴컨은 미래형 자동차 기술 개발의 다양한 시도를 진행중입니다.
자동차 ECU 개방형 표준 소프트웨어 구조를 개발하고 설립을 위한
Auto SAR 개발을 DGIST와 함께하고 있습니다.`,
    mb: `휴컨은 미래형 자동차 기술 개발의
다양한 시도를 진행중입니다.
자동차 ECU 개방형 표준 소프트웨어 구조를
개발하고 설립을 위한 Auto SAR 개발을 DGIST와
함께하고 있습니다.`,
    bg: "pc:bg-carcontentpc3 tablet:bg-carcontenttablet3 mb:bg-carcontentmb3",
  },
  {
    number: "04",
    title: "차량 내 통신 기술",
    pctablet: `휴컨은 무선 메시 네트워크 솔루션을 적용 및 자체적인 센싱 알고리즘을 통해
작업자의 안전을 실시간으로 모니터링 하는 기술에 대한 특허가 등록되어있습니다.
이는 휴컨의 이념인 사람을 위한 기술이 그대로 녹아 있는 솔루션입니다.`,
    mb: `휴컨은 무선 메시 네트워크 솔루션을 적용 및 자체적인
센싱 알고리즘을 통해 작업자의 안전을 실시간으로
모니터링 하는 기술에 대한 특허가 등록되어있습니다.
이는 휴컨의 이념인 사람을 위한 기술이 그대로 녹아 있는 솔루션입니다.`,
    bg: "pc:bg-carcontentpc4 tablet:bg-carcontenttablet4 mb:bg-carcontentmb4",
  },
];
