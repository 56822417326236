import React from "react";
import { Responsiv } from "../../interface/redux/config";
import ScrollNotice from "./ScrollNotice";

type Props = {
  title: string;
  sub: string;
  bgClass: string;
  Desktop: Responsiv;
};

const PageTitleSection = ({ title, sub, bgClass, Desktop }: Props) => {
  return (
    <div
      className={`w-full bg-center bg-black bg-no-repeat bg-cover text-center pc:pt-[299px] tablet:pt-[201px] mb:pt-[185px] nomb:h-[611px] mb:h-[451px] ${bgClass}`}
    >
      <div
        className={`text-white text-xl mb:whitespace-pre-line mb-1 mb:leading-[150%]`}
      >
        {sub}
      </div>
      <div
        className={`text-white font-mont pc:text-[83px] tablet:text-[70px] mb:text-[40px] nopc:whitespace-pre-line leading-[130%]`}
      >
        {title}
      </div>
      <Desktop>
        <div className="flex justify-center">
          <ScrollNotice />
        </div>
      </Desktop>
    </div>
  );
};

export default PageTitleSection;
