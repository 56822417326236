import React from "react";
import { ResponsiveType } from "../../../interface/redux/config";

type Props = {
  Responsive: ResponsiveType;
};

const Cisection2 = ({ Responsive: { Mobile, Tablet, Desktop } }: Props) => {
  return (
    <div className='w-full nomb:h-[611px] bg-mints flex  flex-col justify-center items-center mb:pt-[124px] mb:pb-[100px]'>
      <div className='bg-main nomb:text-[40px] mb:text-[24px] font-[300] font-mont'>
        Spread, Link, Fusion
      </div>
      <div className='text-white nomb:text-[40px] mb:text-[28px] font-bold mb-[56px] mb:text-center mb:mt-[8px] mb:mb-[69px]'>
        넓게 퍼지고,{" "}
        <Mobile>
          <br />
        </Mobile>{" "}
        연결되어,
        <Mobile>
          <br />
        </Mobile>{" "}
        융합되다
      </div>
      <Desktop>
        <img
          src='/assets/about/ci/content/ci-section2.png'
          srcSet='/assets/about/ci/content/ci-section2@2x.png 2x, /assets/about/ci/content/ci-section2@3x.png 3x'
          alt=''
        />
      </Desktop>
      <Tablet>
        <img
          src='/assets/about/ci/content/ci-section2-tablet.png'
          srcSet='/assets/about/ci/content/ci-section2-tablet@2x.png 2x, /assets/about/ci/content/ci-section2-tablet@3x.png 3x'
          alt=''
        />
      </Tablet>
      <Mobile>
        <img
          src='/assets/about/ci/content/ci-section2-mb.png'
          srcSet='/assets/about/ci/content/ci-section2-mb@2x.png 2x, /assets/about/ci/content/ci-section2-mb@3x.png 3x'
          alt=''
        />
      </Mobile>
    </div>
  );
};

export default Cisection2;
