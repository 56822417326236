import React from "react";
import { Responsiv } from "../../../interface/redux/config";

type Props = {
  Desktop: Responsiv;
  Tablet: Responsiv;
  Mobile: Responsiv;
};

const PartnerSection = ({ Desktop, Tablet, Mobile }: Props) => {
  return (
    <div className='pc:w-[1147px] tablet:w-[688px] mb:w-[328px] m-auto pc:py-[290px] tablet:pt-[290px] tablet:pb-[357px] mb:py-[300px] relative mb:flex mb:flex-col mb:items-center'>
      <img
        className='max-w-none absolute pc:top-[178px] tablet:top-[283px] mb:top-[260px] pc:w-[889px] tablet:w-[763px] mb:w-[273px] pc:right-[-476px] tablet:right-[-413px]'
        src='/assets/about/history/partner/partner-back.svg'
        alt=''
      />
      <span className='mb-2 font-bold nomb:text-xl mb:text-sm bg-main'>
        협력사
      </span>
      <div className='text-white font-bold nomb:text-[34px] mb:text-2xl mb:text-center mb:laeding-[140%]'>
        휴컨은 많은 파트너와 <br /> 함께 성장해나가고 있습니다
      </div>
      <div className='grid pc:gap-y-[109px] tablet:gap-y-[168px] mb:gap-y-[159px] nomb:mt-[75px] mb:mt-[29px]'>
        {layout.map(({ title, dir }, idx) => {
          return (
            <div key={idx} className='w-full'>
              <div className='w-full border-b-blue600 border-b-[2px]'>
                <button className='bg-gradient nomb:px-[30px] mb:px-[16px] h-[38px] font-bold nomb:text-xl mb:text-base translate-y-[2px]'>
                  {title}
                </button>
              </div>
              <div className='w-full bg-gray-trans pc:h-[295px] tablet:h-[520px] mb:h-[451px] mb:px-[19px] flex items-center justify-center'>
                <Desktop>
                  <img
                    src={`/assets/about/history/partner/${dir}-pc.png`}
                    srcSet={`/assets/about/history/partner/${dir}-pc@2x.png 2x, /assets/about/history/partner/${dir}-pc@3x.png 3x`}
                    alt=''
                  />
                </Desktop>
                <Tablet>
                  <img
                    src={`/assets/about/history/partner/${dir}-tablet.png`}
                    srcSet={`/assets/about/history/partner/${dir}-tablet@2x.png 2x, /assets/about/history/partner/${dir}-tablet@3x.png 3x`}
                    alt=''
                  />
                </Tablet>
                <Mobile>
                  <img
                    src={`/assets/about/history/partner/${dir}-mb.png`}
                    srcSet={`/assets/about/history/partner/${dir}-mb@2x.png 2x, /assets/about/history/partner/${dir}-mb@3x.png 3x`}
                    alt=''
                  />
                </Mobile>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PartnerSection;

const layout = [
  {
    title: "기업체",
    dir: "partner-1",
  },
  {
    title: "학교 및 관공서",
    dir: "partner-2",
  },
];
