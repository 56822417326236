import React from "react";
import { Responsiv } from "../../interface/redux/config";

type Props = { Mobile: Responsiv };

const MainSection5 = ({ Mobile }: Props) => {
  return (
    <div className="bg-black w-full relative h-[535px] flex justify-center items-center">
      <div className="absolute top-0 w-[1440px] ">
        <img
          className="w-full"
          src="/assets/main/section5/main-section5.png"
          srcSet="/assets/main/section5/main-section5@2x.png 2x, /assets/main/section5/main-section5@3x.png 3x"
          alt=""
        />
      </div>
      <div className="relative z-10 text-center text-white mb:px-5">
        <div className="font-mont font-bold nomb:text-[32px] mb:text-xl leading-[150%]">
          SAFER, MORE CONVENIENT, <br />
          AND MORE INNOVATIVE
          <Mobile>
            <br />
          </Mobile>{" "}
          TCHNOLOGY
        </div>
        <div className="mt-2 mb-6 nomb:text-2xl mb:text-base font-bold leading-[150%]">
          보다 안전한, 보다 편리한,{" "}
          <Mobile>
            <br />
          </Mobile>{" "}
          그리고 보다 혁신적인 기술
        </div>
        <div className="text-sm leading-[160%]">
          휴컨은 보다 안전한 기술, 보다 편리한 기술, 보다 혁신적인 기술을 추구
          합니다. <br /> 우리의 기술이 생활속에 자리 매김하여 사람들에게 감동을
          주는 것이 목표입니다.
        </div>
      </div>
    </div>
  );
};

export default MainSection5;
