import React from "react";

type Props = {};

const CiTop = (props: Props) => {
  return (
    <div
      className={`w-full bg-center bg-black bg-no-repeat bg-cover text-center flex justify-center items-center flex-col h-[270px] pc:bg-cititle tablet:bg-cititle-tablet mb:bg-cititle-mb`}
    >
      <div
        className={`text-white text-xl mb:whitespace-pre-line mb-1 mb:leading-[150%]`}
      >
        CI 소개
      </div>
      <div
        className={`text-white font-mont pc:text-[83px] tablet:text-[70px] mb:text-[40px] nopc:whitespace-pre-line leading-[130%]`}
      >
        HUCCONN CI
      </div>
    </div>
  );
};

export default CiTop;
