import React, { useState } from "react";
import BusinessSubNav from "../../components/Business/BusinessSubNav/BusinessSubNav";
import BusinessTitleSection from "../../components/Business/BusinessTitleSection/BusinessTitleSection";
import SmartContentBox from "../../components/Business/Smart/SmartContentBox/SmartContentBox";
import { Responsiv } from "../../interface/redux/config";

type Props = {
  Desktop: Responsiv;
  PcTablet: Responsiv;
  Tablet: Responsiv;
  Mobile: Responsiv;
};

const Smart = ({ Desktop, PcTablet, Mobile, Tablet }: Props) => {
  const [NavIndex, setNavIndex] = useState<number | undefined>(undefined);
  return (
    <main className='bg-black'>
      <BusinessTitleSection
        Desktop={Desktop}
        PcTablet={PcTablet}
        Mobile={Mobile}
        tag='HUCONN BUSINESS | 스마트 솔루션'
        title={`SMART
SOLUTION`}
        sub={`HUCONN-Smart Factory는 MES를 중심으로 ERP SCM PLM LIMS 등
다양한 정보시스템과 상호 연계하여
기업의 경쟁력 강화를 위한 최적의 모델을 제공합니다.`}
        mb={`HUCONN-Smart Factory는 MES를 
중심으로 ERP SCM PLM LIMS 등
다양한 정보시스템과 상호 연계하여
기업의 경쟁력 강화를 위한 
최적의 모델을 제공합니다.`}
        bgstyle='pc:bg-smart-title-pc tablet:bg-smart-title-tablet mb:bg-smart-title-mb'
      />
      <BusinessSubNav
        layout={Nav_layout}
        nowNav={NavIndex !== undefined ? NavIndex : 0}
        setNav={setNavIndex}
      />
      <div className='pc:w-[1147px] tablet:px-[20px] m-auto pt-[181px] pb-[268px]'>
        {layout.map((item, idx) => {
          return (
            <SmartContentBox
              Desktop={Desktop}
              PcTablet={PcTablet}
              Mobile={Mobile}
              Tablet={Tablet}
              data={item}
              isScroll={NavIndex === idx}
              key={idx}
            />
          );
        })}
      </div>
    </main>
  );
};

export default Smart;

const Nav_layout = ["운용모델", "진행절차", "구축 후 효과"];

const layout = [
  {
    tag: "운용모델",
    title: "스마트팩토리 운용모델 ",
    sub: `HUCONN-Smart Factory는 MES를 중심으로 ERP SCM PLM LIMS 등
다양한 정보시스템과 상호 연계하여
기업의 경쟁력 강화를 위한 최적의 모델을 제공합니다.`,
    submb: `HUCONN-Smart Factory는 MES를 중심으로 
ERP SCM PLM LIMS 등 다양한 정보시스템과
상호 연계하여 기업의 경쟁력 강화를 위한 
최적의 모델을 제공합니다.`,
    type: "model",
  },
  {
    tag: "진행절차",
    title: "스마트팩토리 진행절차",
    sub: `휴컨은 꼼꼼한 현황 분석을 기반으로
체계적인 목표모델을 수립하여 이행 계획을 수행합니다.`,
    submb: `휴컨은 꼼꼼한 현황 분석을 기반으로 체계적인
목표모델을 수립하여 이행 계획을 수행합니다.`,
    type: "progress",
  },
  {
    tag: "구축 후 효과",
    title: `HU-스마트팩토리로
생산성을 높여보세요`,
    sub: `휴컨은 꼼꼼한 현황 분석을 기반으로
체계적인 목표모델을 수립하여 이행 계획을 수행합니다.`,
    submb: `휴컨은 꼼꼼한 현황 분석을 기반으로
체계적인 목표모델을 수립하여
이행 계획을 수행합니다.`,
    type: "build",
  },
];
