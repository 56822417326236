import React from "react";
import { Link } from "react-router-dom";

type Props = {};

const IntroSection3 = (props: Props) => {
  return (
    <div className="grid gap-y-[32px] pc:w-[1144px] tablet:w-[688px] mb:w-[328px] m-auto pb-[150px]">
      {layout.map(({ tag, title, btn, link }, idx) => {
        return (
          <div
            key={idx}
            className={`w-full h-[237px] bg-no-repeat bg-cover bg-center pt-[40px] pc:pl-[45px] tablet:pl-[31px] mb:pl-[21px] ${
              idx === 0
                ? "nomb:bg-introsection3pc1 mb:bg-introsection3mb1"
                : "nomb:bg-introsection3pc2 mb:bg-introsection3mb2"
            }`}
          >
            <div className="text-sm font-bold text-white font-mont">{tag}</div>
            <div className="mt-2 mb-3 text-2xl font-bold text-white whitespace-pre-line laeding-[150%]">
              {title}
            </div>
            <Link
              to={link}
              className="group bg-white w-[184px] h-[44px] flex items-center justify-between px-4 font-mont font-bold text-sm whitespace-nowrap transition-all hover:bg-black hover:text-white"
            >
              {btn}
              <ArrowSvg width={idx === 0 ? 66 : 32} x={idx === 0 ? 0 : 33} />
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default IntroSection3;

const ArrowSvg = ({ width, x }: { width: number; x: number }) => {
  return (
    <svg
      width="67"
      height="12"
      viewBox="0 0 67 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.5303 6.53033C66.8232 6.23744 66.8232 5.76256 66.5303 5.46967L61.7574 0.696699C61.4645 0.403806 60.9896 0.403806 60.6967 0.696699C60.4038 0.989593 60.4038 1.46447 60.6967 1.75736L64.9393 6L60.6967 10.2426C60.4038 10.5355 60.4038 11.0104 60.6967 11.3033C60.9896 11.5962 61.4645 11.5962 61.7574 11.3033L66.5303 6.53033Z"
        className="fill-black group-hover:fill-white"
      />
      <rect
        x={x}
        y="5.25"
        width={width}
        height="1.5"
        className="fill-black group-hover:fill-white"
      />
    </svg>
  );
};

const layout = [
  {
    tag: "신입/경력 채용 중!",
    title: `이런 휴컨에서
함께 일하고 싶다면?`,
    btn: "채용공고",
    link: "/culture/job",
  },
  {
    tag: "고객 문의",
    title: `궁금하신 점이
있나요?`,
    btn: "CONTACT US",
    link: "/contact",
  },
];
