import { createSlice } from "@reduxjs/toolkit";
import { languageType } from "../../interface/redux/config";

type StateType = {
  productionPath: "test" | "production";
  language: languageType;
};

const initialState: StateType = {
  productionPath: "test",
  language: "kr",
};

const configReducer = createSlice({
  name: "config",
  initialState,
  reducers: {
    LanguageChange: (state, { payload }: { payload: languageType }) => {
      state.language = payload;
    },
  },
});

export const { LanguageChange } = configReducer.actions;

export default configReducer.reducer;
