import { findByTitle } from "@testing-library/react";
import React from "react";
import { Responsiv } from "../../interface/redux/config";

type Props = {
  PcTablet: Responsiv;
  Mobile: Responsiv;
  title: string;
  sub: JSX.Element;
  layout: {
    number: string;
    title: string;
    pctablet: string;
    mb: string;
    bg: string;
  }[];
};

const RndContentSection = ({ PcTablet, Mobile, layout, title, sub }: Props) => {
  return (
    <div className="flex flex-col items-center bg-black nomb:pt-[110px] mb:pt-[125px] pc:pb-[172px] tablet:pb-[130px] mb:pb-[176px]">
      <div className="text-lg text-white font-mont">R&D</div>
      <div className="bg-main text-[40px] font-bold mt-1 mb-3">{title}</div>
      <div className="text-base text-center text-white">{sub}</div>
      <div className="grid pt-16 gap-y-8">
        {layout.map(({ number, title, pctablet, bg, mb }, idx) => {
          return (
            <div
              key={idx}
              className={`${bg} h-[270px] bg-center bg-cover bg-no-repeat pt-[60px] pc:pl-[48px] tablet:pl-[28px] mb:pl-[13px] pc:w-[1148px] tablet:w-[688px] mb:w-[320px]`}
            >
              <div className="nomb:text-2xl mb:text-[14px] bg-sub w-fit font-mont">
                {number}
              </div>
              <div className="my-1 font-bold text-white nomb:text-[32px] mb:text-[24px] font-mont">
                {title}
              </div>
              <PcTablet>
                <div className="text-white whitespace-pre-line text-[18px]">
                  {pctablet}
                </div>
              </PcTablet>
              <Mobile>
                <div className="text-white whitespace-pre-line text-[14px]">
                  {mb}
                </div>
              </Mobile>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RndContentSection;
