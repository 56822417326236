import React from "react";
import { ResponsiveType } from "../../interface/redux/config";
import IntroSection3 from "../../components/About/Intro/IntroSection3";
import CiTop from "../../components/About/CiIntro/CiTop";
import CiSection1 from "../../components/About/CiIntro/CiSection1";
import Cisection2 from "../../components/About/CiIntro/Cisection2";
import CiSection3 from "../../components/About/CiIntro/CiSection3";
import CiColor from "../../components/About/CiIntro/CiColor";

type Props = {
  Responsive: ResponsiveType;
};

const CiIntro = ({ Responsive }: Props) => {
  return (
    <main className='w-full min-h-full bg-black pt-[66px]'>
      <CiTop />
      <CiSection1 Responsive={Responsive} />
      <Cisection2 Responsive={Responsive} />
      <CiSection3 Responsive={Responsive} />
      <CiColor />
      <IntroSection3 />
    </main>
  );
};

export default CiIntro;
