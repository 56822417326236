import React from "react";
import { Responsiv } from "../../../interface/redux/config";
import ScrollNotice from "../../Common/ScrollNotice";

type Props = {
  tag: string;
  title: string;
  sub: string;
  mb: string;
  bgstyle: string;
  Mobile: Responsiv;
  PcTablet: Responsiv;
  Desktop: Responsiv;
};

const BusinessTitleSection = ({
  tag,
  title,
  sub,
  bgstyle,
  mb,
  PcTablet,
  Mobile,
  Desktop,
}: Props) => {
  return (
    <div className="pt-[66px]">
      <div className="pc:pt-[70px] tablet:pt-[90px] mb:pt-[76px] flex flex-col items-center text-center gap-y-[5px]">
        <div className="text-xl text-white">{tag}</div>
        <div className="bg-main w-fit font-mont pc:text-[83px] tablet:text-[70px] font-[300] tablet:mb-[6px] mb:mb-[8px] mb:whitespace-pre-line mb:text-[40px] mb:leading-[130%]">
          {title}
        </div>
        <PcTablet>
          <div className="text-base whitespace-pre-line pc:mb-6 text-gray300 ">
            {sub}
          </div>
        </PcTablet>
        <Mobile>
          <div className="text-base whitespace-pre-line text-gray300 leading-[160%]">
            {mb}
          </div>
        </Mobile>
        <Desktop>
          <ScrollNotice isBusiness={true} />
        </Desktop>
      </div>
      <div
        className={`w-full bg-center bg-cover bg-no-repeat h-[397px] pc:mt-[40px] tablet:mt-[99px] mb:mt-[85px] ${bgstyle}`}
      />
    </div>
  );
};

export default BusinessTitleSection;
