import React from "react";
import { ResponsiveType } from "../../../interface/redux/config";

type Props = {
  Responsive: ResponsiveType;
};

const CiSection1 = ({
  Responsive: { Desktop, Tablet, Mobile, PcTablet },
}: Props) => {
  return (
    <div className='flex items-center justify-center flex-col pc:pb-[142px] tablet:pb-[148px] mb:pb-[145px]'>
      <div className='text-white flex pc:items-center w-[1144px] tablet:w-[728px] mb:w-[320px] pt-[92px] pc:pb-[42px] tablet:pb-[52px] mb:pb-[36px]  gap-x-[79px] nopc:flex-col'>
        <div>
          <span className='nomb:text-[18px] mb:text-[14px] bg-main font-bold'>
            CI 소개
          </span>
          <div className='font-bold  nomb:text-[40px] mb:text-[32px] leading-[140%] mt-[10px] '>
            미래를 위한 기술을 향한 <br /> 새로운 융합
          </div>
        </div>
        <div className='text-[14px] leading-[160%] pt-[20px]'>
          기본이 되는 기술, 기업과 기업을 연결해주는 인프라와 프로세스{" "}
          <PcTablet>
            <br />
          </PcTablet>
          다양한 환경의 요소들이 모여 휴컨만의 단단한 기술력을 이끌어냅니다.{" "}
          <PcTablet>
            <br />
          </PcTablet>
          요소들이 모여 퍼지고 동시에 연결되는 이미지를 통해{" "}
          <PcTablet>
            <br />
          </PcTablet>{" "}
          미래를 위한 기술을 향한 융합을 나타냅니다.
        </div>
      </div>
      <Desktop>
        <img
          src='/assets/about/ci/content/ci-content.png'
          srcSet='/assets/about/ci/content/ci-content@2x.png 2x, /assets/about/ci/content/ci-content@3x.png 3x'
          alt=''
        />
      </Desktop>
      <Tablet>
        <img
          src='/assets/about/ci/content/ci-content-tablet.png'
          srcSet='/assets/about/ci/content/ci-content-tablet@2x.png 2x, /assets/about/ci/content/ci-content-tablet@3x.png 3x'
          alt=''
        />
      </Tablet>
      <Mobile>
        <img
          src='/assets/about/ci/content/ci-content-mb.png'
          srcSet='/assets/about/ci/content/ci-content-mb@2x.png 2x, /assets/about/ci/content/ci-content-mb@3x.png 3x'
          alt=''
        />
      </Mobile>
    </div>
  );
};

export default CiSection1;
