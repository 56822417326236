import React from "react";

type Props = {
  isBusiness?: boolean;
};

const ScrollNotice = ({ isBusiness }: Props) => {
  return (
    <div
      className={`pc:w-[53px] pc:h-[78px] ${
        isBusiness ? "bg-gray100" : "bg-black/30"
      } gap-y-[5px] flex flex-col items-center justify-center rounded-[42px]`}
    >
      <div className="overflow-hidden">
        <img
          src="/assets/common/scroll.svg"
          alt="scroll"
          className="animate-scroll-spin"
        />
      </div>
      <div className="text-[10px] font-mont text-white">SCROLL</div>
    </div>
  );
};

export default ScrollNotice;
