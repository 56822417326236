import React from "react";
import { Responsiv } from "../../../interface/redux/config";

type Props = {
  Tablet: Responsiv;
  Desktop: Responsiv;
};

const TopstSection1 = ({ Tablet, Desktop }: Props) => {
  return (
    <div className='text-white pc:w-[1153px] tablet:w-[688px] mb:w-[328px] m-auto pc:pt-[168px] tablet:pt-[91px] mb:pt-[100px] pc:pb-[141px] nopc:pb-[160px]'>
      <span className='nomb:text-[18px] mb:text-[14px] font-bold bg-sub'>
        TOPST
      </span>
      <div className='font-bold nomb:text-[32px] mb:text-[24px] my-[6px]'>
        K-시스템 반도체 오픈 플랫폼 TOPST
      </div>
      <div className='text-[14px] leading-[160%] '>
        • 누구나 접근 가능하도록 디자인이 공개된 하드웨어와 소프트웨어 – 회로도,
        PCB, Source Code, 등… <br /> • 다양한 기능의 Accessory 보드 제공(Camera,
        Display, WIFI/BT, 센서,액츄에이터등)
        <br /> • 이를 이용하여, 누구나 학습하고, 만들고, 배포하여, 제조, 판매 할
        수 있도록 한다 <br /> • 누구나 디자인을 자유롭게 교환함으로써 지식을
        공유하게 한다 <br /> • 누구나 자유롭게 기술을 제어하고 사용한다
      </div>
      <Desktop>
        <img
          className='mt-[37px]'
          src='/assets/business/topst/content/topst-section1-pc.png'
          srcSet='/assets/business/topst/content/topst-section1-pc@2x.png 2x, /assets/business/topst/content/topst-section1-pc@3x.png 3x'
          alt=''
        />
      </Desktop>
      <Tablet>
        <img
          className='mt-[40px]'
          src='/assets/business/topst/content/topst-section1-tablet.png'
          srcSet='/assets/business/topst/content/topst-section1-tablet@2x.png 2x, /assets/business/topst/content/topst-section1-tablet@3x.png 3x'
          alt=''
        />
      </Tablet>
    </div>
  );
};

export default TopstSection1;
