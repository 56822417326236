import React from "react";
import { Link } from "react-router-dom";
import { Responsiv } from "../../interface/redux/config";

type Props = {
  PcTablet: Responsiv;
};

const MainSection3 = ({ PcTablet }: Props) => {
  return (
    <div className="bg-black relative flex flex-col items-center pc:pt-[143px] tablet:pt-[168px] mb:pt-[100px]">
      <div className="nomb:w-[1440px] mb:w-[768px] absolute top-0">
        <img
          src="/assets/main/section3/main-section3-back.png"
          srcSet="/assets/main/section3/main-section3-back@2x.png 2x, /assets/main/section3/main-section3-back@3x.png 3x"
          alt="back"
        />
      </div>
      <div className="relative z-10 flex flex-col items-center text-center">
        <div className="text-xl font-bold bg-main font-mont">R&D</div>
        <div className="text-white font-bold nomb:text-[32px] mb:text-2xl leading-[150%] mt-4 mb-3 font-mont">
          우리는 사람과 미래를 위해 <br /> 기술을 연구합니다.
        </div>
        <div className="text-white text-sm leading-[160%] font-mont mb:px-[20px]">
          휴컨은 에너지와 자동차 전장 분야를 연구하는 기업입니다.{" "}
          <PcTablet>
            <br />
          </PcTablet>
          에너지와 자동차는 기술 융합 시대에 있어 중요한 성장 분야이기에{" "}
          <PcTablet>
            <br />
          </PcTablet>
          지속적인 연구와 개발을 통해 더 나은 미래 가치를 추구하고자 합니다.
        </div>
      </div>
      <div className="flex pc:gap-x-4 nopc:flex-col tablet:gap-y-8 mb:gap-y-[23px] relative z-10 pc:mt-[54px] tablet:mt-[56px] mb:mt-[32px] mb:w-full mb:px-[20px]">
        {layout.map(({ tag, title, sub, content, img, link }, idx) => {
          return (
            <div key={idx} className="nomb:w-[559px] bg-gray500">
              <div className="nomb:h-[347px] mb:h-[282px] nomb:pt-[45px] nomb:pl-[47px] mb:p-7">
                <div className="text-white font-mont nomb:text-lg mb:text-sm">
                  {tag}
                </div>
                <div className="font-mont bg-main w-fit my-1 font-bold nomb:text-3xl mb:text-xl leading-[130%]">
                  {title}
                </div>
                <div className="font-mont text-white nomb:text-[20px] mb:text-[18px] font-bold">
                  {sub}
                </div>
                <div className="text-white leading-[160%] text-sm whitespace-pre-line my-3">
                  {content}
                </div>
                <Link
                  className="flex items-center justify-center text-white w-[130px] h-[42px] bg-black font-extrabold text-base transition-all hover:opacity-70"
                  to={link}
                >
                  자세히보기
                </Link>
              </div>
              <div className="mb:w-full">
                <img
                  className="w-full"
                  src={`/assets/main/section3/${img}.png`}
                  srcSet={`/assets/main/section3/${img}@2x.png 2x, /assets/main/section3/${img}@3x.png 3x`}
                  alt=""
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MainSection3;

const layout = [
  {
    tag: "R&D 01",
    title: (
      <>
        ENERGY <br /> DEVELOPMENT
      </>
    ),
    sub: "에너지 개발",
    content: `안전 설계 및 무선 통신 기술을 이용한 ESS와
이동형 충전 배터리 개발을 수행합니다.`,
    img: "main-section3-card-1",
    link: "/rnd/energy",
  },
  {
    tag: "R&D 02",
    title: (
      <>
        CAR INFOTAINMENT <br /> DEVELOPMENT
      </>
    ),
    sub: "자동차 전장개발",
    content: `자동차 전장, 특히 인포테인먼트와
차량내 네트워크 기술 개발을 수행합니다.`,
    img: "main-section3-card-2",
    link: "/rnd/car",
  },
];
