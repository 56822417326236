import React, { Dispatch, SetStateAction } from "react";
import { Link, NavigateFunction } from "react-router-dom";
import { languageType } from "../../../interface/redux/config";

type Props = {
  Nav_layout: string[];
  Open_Nav_layout: {
    title: string;
    list: { title: string; link: string }[];
  }[];
  navigate: NavigateFunction;
  setIsHover: Dispatch<SetStateAction<boolean>>;
  isHover: boolean;
  language: languageType;
  ChangeLanguage: () => void;
  pathname: string;
};

const DeskHeader = ({
  Nav_layout,
  Open_Nav_layout,
  navigate,
  setIsHover,
  isHover,
  language,
  ChangeLanguage,
  pathname,
}: Props) => {
  return (
    <>
      <header className="fixed top-0 left-0 z-50 flex items-center justify-between w-full h-16 bg-black px-14">
        <div className="flex items-center gap-x-14">
          <Link className="w-28" to={"/"}>
            <img
              src="/assets/header/logo.png"
              srcSet="/assets/header/logo@2x.png 2x, /assets/header/logo@3x.png 3x"
              alt=""
            />
          </Link>
          <div className="flex cursor-pointer gap-x-7">
            {Nav_layout.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className="flex items-center font-bold text-white gap-x-1"
                  onClick={() => {
                    if (idx !== 4) {
                      setIsHover(!isHover);
                    } else {
                      navigate("/contact");
                    }
                  }}
                >
                  {item}
                  {idx !== 4 ? (
                    <div className="w4">
                      <img src="/assets/header/arrow.svg" alt="" />
                    </div>
                  ) : undefined}
                </div>
              );
            })}
          </div>
        </div>
        <button
          className="flex items-center transition-all gap-x-2 hover:opacity-70"
          onClick={ChangeLanguage}
        >
          <div className="w-7 ">
            <img
              src={`/assets/header/${language === "kr" ? "korea" : "usa"}.png`}
              srcSet={`/assets/header/${
                language === "kr" ? "korea" : "usa"
              }@2x.png 2x, /assets/header/${
                language === "kr" ? "korea" : "usa"
              }@3x.png 3x`}
              alt=""
            />
          </div>
          <div className="text-base font-bold text-white ">
            {language === "kr" ? "KOREAN" : "ENGLISH"}
          </div>
        </button>
      </header>
      <div
        className={`fixed left-0 z-40 flex gap-x-11 bg-black h-64 w-full transition-all duration-300 ease-in-out pl-60 pt-top18 ${
          isHover ? "top-16" : "top-[-300px]"
        }`}
      >
        {Open_Nav_layout.map(({ title, list }, idx) => {
          return (
            <div key={idx} className="flex flex-col gap-y-4">
              <div className="text-base font-bold bg-main w-fit">{title}</div>
              {list.map(({ title, link }, idx) => {
                return (
                  <Link
                    key={idx}
                    to={link}
                    className={`font-bold text-sm ${
                      link === pathname ? "text-blue" : "text-white"
                    }`}
                  >
                    {title}
                  </Link>
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default DeskHeader;
