import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { languageType, ResponsiveType } from "../../interface/redux/config";
import { RootState } from "../../reducers";
import { LanguageChange } from "../../reducers/config";
import DeskHeader from "./Responsive/DeskHeader";
import TabletMobileHeader from "./Responsive/TabletMobileHeader";

const Header = ({ Desktop, Mobile, Tablet, TabletMobile }: ResponsiveType) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const language = useSelector<RootState, languageType>(
    (state) => state.config.language
  );
  const [isHover, setIsHover] = useState<boolean>(false);

  const ChangeLanguage = useCallback(() => {
    dispatch(LanguageChange(language === "kr" ? "eng" : "kr"));
  }, [language, dispatch]);

  useEffect(() => {
    document.getElementById("root")?.scrollTo(0, 0);
    setIsHover(false);
    return () => {};
  }, [pathname]);

  return (
    <>
      <Desktop>
        <DeskHeader
          Nav_layout={Nav_layout}
          Open_Nav_layout={Open_Nav_layout}
          navigate={navigate}
          setIsHover={setIsHover}
          isHover={isHover}
          language={language}
          ChangeLanguage={ChangeLanguage}
          pathname={pathname}
        />
      </Desktop>
      <TabletMobile>
        <TabletMobileHeader
          Mobile={Mobile}
          Tablet={Tablet}
          Open_Nav_layout={MB_Nav_layout}
          setIsHover={setIsHover}
          isHover={isHover}
          language={language}
          ChangeLanguage={ChangeLanguage}
          pathname={pathname}
        />
      </TabletMobile>
    </>
  );
};

export default Header;

const Nav_layout = ["ABOUT", "R&D", "BUSINESS", "CULTURE", "CONTACT US"];

const Open_Nav_layout = [
  {
    title: "ABOUT",
    list: [
      {
        title: "회사소개",
        link: "/about/intro",
      },
      {
        title: "기업연혁",
        link: "/about/history",
      },
      {
        title: "CI 소개",
        link: "/about/ci",
      },
    ],
  },
  {
    title: "R&D",
    list: [
      {
        title: "자동차 전장",
        link: "/rnd/car",
      },
      {
        title: "에너지 개발",
        link: "/rnd/energy",
      },
    ],
  },
  {
    title: "BUSINESS",
    list: [
      {
        title: "SMART SOLUTION",
        link: "/business/smart",
      },
      {
        title: "TOPST",
        link: "/business/topst",
      },
      // {
      //   title: "EDUCATION SOLUTION",
      //   link: "/business/education",
      // },
      // {
      //   title: "ODM",
      //   link: "/business/odm",
      // },
    ],
  },
  {
    title: "CULTURE",
    list: [
      {
        title: "채용공고",
        link: "/culture/job",
      },
    ],
  },
  // {
  //   title: "CONTACT US",
  //   list: [],
  // },
];

const MB_Nav_layout = [
  {
    title: "ABOUT",
    type: "/about",
    list: [
      {
        title: "회사소개",
        link: "/about/intro",
      },
      {
        title: "기업연혁",
        link: "/about/history",
      },
      {
        title: "CI 소개",
        link: "/about/ci",
      },
    ],
  },
  {
    title: "R&D",
    type: "/rnd",
    list: [
      {
        title: "자동차 전장",
        link: "/rnd/car",
      },
      {
        title: "에너지 개발",
        link: "/rnd/energy",
      },
    ],
  },
  {
    title: "BUSINESS",
    type: "/business",
    list: [
      {
        title: "SMART SOLUTION",
        link: "/business/smart",
      },
      {
        title: "TOPST",
        link: "/business/topst",
      },
    ],
  },
  {
    title: "CULTURE",
    type: "/culture",
    list: [
      {
        title: "채용공고",
        link: "/culture/job",
      },
    ],
  },
  {
    title: "CONTACT US",
    type: "/contact",
    list: [],
  },
];
