import React from "react";
import { Responsiv } from "../../../interface/redux/config";

type Props = {
  Tablet: Responsiv;
  Desktop: Responsiv;
  Mobile: Responsiv;
};

const TopstSection2 = ({ Tablet, Desktop, Mobile }: Props) => {
  return (
    <div className='text-white pc:w-[1153px] tablet:w-[688px] mb:w-[328px] m-auto pc:pb-[256px] nopc:pb-[224px]'>
      <span className='nomb:text-[18px] mb:text-[14px] font-bold bg-sub'>
        활용
      </span>
      <div className='font-bold nomb:text-[32px] mb:text-[24px] mt-[6px] mb-[26px]'>
        다양하게 활용될 수 있는 TOPST 플랫폼
      </div>
      <div className='grid pc:gap-y-[70px] tablet:gap-y-[158px] mb:gap-y-[69px]'>
        {topstSection2Layouts.map(({ title, img }, idx) => {
          return (
            <div
              key={idx}
              className='flex justify-between border-t-[1px] border-gray400 border-solid nomb:pt-[24px] mb:pt-[18px] nopc:flex-col nopc:gap-y-[23px]'
            >
              <div className=' nomb:text-[24px] mb:text-[18px] font-bold whitespace-pre-wrap leading-[160%]'>
                {title}
              </div>
              <Desktop>
                <img
                  src={`/assets/business/topst/content/${img}.png`}
                  srcSet={`/assets/business/topst/content/${img}@2x.png 2x, /assets/business/topst/content/${img}@3x.png 3x`}
                  alt=''
                />
              </Desktop>
              <Tablet>
                <img
                  src={`/assets/business/topst/content/${img}-tablet.png`}
                  srcSet={`/assets/business/topst/content/${img}-tablet@2x.png 2x, /assets/business/topst/content/${img}-tablet@3x.png 3x`}
                  alt=''
                />
              </Tablet>
              <Mobile>
                <img
                  src={`/assets/business/topst/content/${img}-mb.png`}
                  srcSet={`/assets/business/topst/content/${img}-mb@2x.png 2x, /assets/business/topst/content/${img}-mb@3x.png 3x`}
                  alt=''
                />
              </Mobile>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TopstSection2;

const topstSection2Layouts = [
  {
    title: `멀티 카메라 멀티 디스플레이를 이용한
전장용 응용 확장`,
    img: "topst-section2-1",
  },
  {
    title: "다양한 분야의 응용 개발 확장",
    img: "topst-section2-2",
  },
];
