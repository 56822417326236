import React from "react";
import { Route, Routes } from "react-router-dom";
import { ResponsiveType } from "../interface/redux/config";
import RndCar from "../pages/Rnd/sub/RndCar";
import RndEnergy from "../pages/Rnd/sub/RndEnergy";

const RndRoute = ({ Desktop ,PcTablet,Mobile }: ResponsiveType) => {
  return (
    <Routes>
      <Route path="/car" element={<RndCar Desktop={Desktop} PcTablet={PcTablet} Mobile={Mobile}/>} />
      <Route path="/energy" element={<RndEnergy Desktop={Desktop} PcTablet={PcTablet} Mobile={Mobile}/>} />
    </Routes>
  );
};

export default RndRoute;
