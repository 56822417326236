import React from "react";
import { Route, Routes } from "react-router-dom";
import { ResponsiveType } from "../interface/redux/config";
import Education from "../pages/Business/Education";
import Odm from "../pages/Business/Odm";
import Smart from "../pages/Business/Smart";
import Topst from "../pages/Business/Topst";

const BusinessRoute = ({
  Desktop,
  PcTablet,
  Mobile,
  Tablet,
}: ResponsiveType) => {
  return (
    <Routes>
      <Route
        path='/smart'
        element={
          <Smart
            Desktop={Desktop}
            PcTablet={PcTablet}
            Mobile={Mobile}
            Tablet={Tablet}
          />
        }
      />
      <Route
        path='/topst'
        element={
          <Topst
            Desktop={Desktop}
            PcTablet={PcTablet}
            Mobile={Mobile}
            Tablet={Tablet}
          />
        }
      />
      <Route
        path='/education'
        element={
          <Education Desktop={Desktop} PcTablet={PcTablet} Mobile={Mobile} />
        }
      />
      <Route
        path='/odm'
        element={<Odm Desktop={Desktop} PcTablet={PcTablet} Mobile={Mobile} />}
      />
    </Routes>
  );
};

export default BusinessRoute;
