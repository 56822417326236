import React from "react";
import { Responsiv, ResponsiveType } from "../../interface/redux/config";
import BusinessTitleSection from "../../components/Business/BusinessTitleSection/BusinessTitleSection";
import TopstSection1 from "../../components/Business/Topst/TopstSection1";
import TopstSection2 from "../../components/Business/Topst/TopstSection2";

type Props = {
  Desktop: Responsiv;
  PcTablet: Responsiv;
  Tablet: Responsiv;
  Mobile: Responsiv;
};

const Topst = ({ Desktop, PcTablet, Mobile, Tablet }: Props) => {
  return (
    <div>
      <BusinessTitleSection
        Desktop={Desktop}
        PcTablet={PcTablet}
        Mobile={Mobile}
        tag='HUCONN BUSINESS | TOPST'
        title={`TOPST`}
        sub={`Total Open Platform for System development and Training
누구나 자유롭게 사용하는 기술, 누구나 쉽게 설계할 수 있는 기술`}
        mb={`Total Open Platform for
System development and Training
누구나 자유롭게 사용하는 기술,
누구나 쉽게 설계할 수 있는 기술`}
        bgstyle='pc:bg-topst-title-pc tablet:bg-topst-title-tablet mb:bg-topst-title-mb'
      />
      <TopstSection1 Desktop={Desktop} Tablet={Tablet} />
      <TopstSection2 Desktop={Desktop} Tablet={Tablet} Mobile={Mobile} />
    </div>
  );
};

export default Topst;
