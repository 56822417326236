import React from "react";
import MainSection6 from "../../components/Main/MainSection6";
import { Responsiv } from "../../interface/redux/config";

type Props = {
  PcTablet: Responsiv;
  Mobile: Responsiv;
};

const Contact = ({ PcTablet, Mobile }: Props) => {
  return (
    <>
      <PcTablet>
        <main
          className="flex items-center bg-black mb:h-screen"
          style={{ height: "calc(100% - 314px)" }}
        >
          <MainSection6 style="justify-center " />
        </main>
      </PcTablet>
      <Mobile>
        <main className="flex items-center h-full bg-black">
          <MainSection6 style="justify-center " />
        </main>
      </Mobile>
    </>
  );
};

export default Contact;
