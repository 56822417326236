import React from "react";
import { Link } from "react-router-dom";

type Props = {};

const MainSection4 = (props: Props) => {
  return (
    <div className="bg-black pc:py-[277px] tablet:py-[246px] mb:py-[208px]">
      <div className="pc:w-[1146px] tablet:w-[636px] mb:px-5  m-auto">
        <div className="bg-main font-mont w-fit font-bold nomb:text-xl mb:text-base nopc:m-auto">
          BUSINESS
        </div>
        <div className="text-white font-mont nomb:text-[50px] mb:text-2xl font-bold my-2 leading-none nopc:text-center">
          HUCONN SOLUTION
        </div>
        <div className="text-white font-mont nomb:text-lg mb:text-sm nopc:text-center">
          휴컨은 미래의 기술을 함께 준비합니다
        </div>
        <div className="grid pc:gap-y-[67px] tablet:gap-y-[88px] mb:gap-y-[80px] pc:mt-[67px] tablet:mt-[92px] mb:mt-[64px]">
          {layout.map(({ tag, title, sub, link, img_title, img }, idx) => {
            return (
              <div
                key={idx}
                className="flex pc:items-end nopc:flex-col relative pc:h-[337px] tablet:gap-y-8 mb:gap-y-6"
              >
                <div className="relative z-10">
                  <div className="bg-main font-mont w-fit font-bold nomb:text-lg mb:text-sm">
                    {tag}
                  </div>
                  <div className="text-white font-extrabold nomb:text-3xl mb:text-2xl mt-1">
                    {title}
                  </div>
                  <div className="pc:w-[636px] nopc:w-full bg-white h-[1px] my-4" />
                  <div className="text-white whitespace-pre-line text-sm leading-[160%]">
                    {sub}
                  </div>
                  {idx === 0 ? (
                    <Link
                      to={link}
                      className="flex items-center justify-center w-[130px] h-[42px] bg-white mt-4 text-base font-extrabold transition-all hover:bg-black hover:text-white hover:border"
                    >
                      자세히보기
                    </Link>
                  ) : (
                    <div className="flex items-center justify-center w-[130px] h-[42px] bg-trans mt-4 text-base font-extrabold transition-all "></div>
                  )}
                </div>
                <div
                  className={`${img} pc:w-[882px] nopc:w-full nomb:h-[337px] mb:h-[40vw] mb:min-h-[170px] pc:absolute pc:right-[-170px] pt-[30px] pc:pl-11 tablet:pl-8 mb:pl-[32px] bg-cover bg-no-repeat bg-center`}
                >
                  <div className="font-mont text-white whitespace-pre-line nomb:text-[32px] mb:text-xl leading-[130%]">
                    {img_title}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MainSection4;

const layout = [
  {
    tag: "BUSINESS 01",
    title: "스마트 솔루션",
    sub: `효율적인 스마트공장 및 스마트팜을 위한
자동화 장비 개발  및 ISP, MES, ERP, SCM 기획
`,
    link: "/business/smart",
    img_title: `01
SMART
SOLUTION`,
    img: "bg-smart",
  },
  {
    tag: "BUSINESS 02",
    title: "교육 솔루션",
    sub: `신설학과 및 학과개편 컨설팅,
교육훈련 장비 기획 및 제조, 교육지원`,
    link: "/business/education",
    img_title: `02
EDUCATION
SOLUTION`,
    img: "bg-education",
  },
  {
    tag: "BUSINESS 03",
    title: "ODM",
    sub: "아이디어 제품 형상화, 시제품 제작 지원",
    link: "/business/odm",
    img_title: `03
ODM`,
    img: "bg-odm",
  },
];
